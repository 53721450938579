import { useEventListener } from "@vueuse/core";
import { useRouter } from "vue-router";
import { useTestConfirmDialog } from "./useTestConfirmDialog";

export function useDialogDirtyCheck(isDirty?: () => boolean) {
  const confirm = useTestConfirmDialog();
  const router = useRouter();

  // Handle browser/tab close events
  useEventListener(window, "beforeunload", (evt: BeforeUnloadEvent) => {
    if (isDirty && isDirty()) {
      evt.preventDefault();
    }
  });

  // Add global navigation guard
  router.beforeEach(async (_, __, next) => {
    if (isDirty && isDirty()) {
      const confirmed = await confirm.require({
        title: "You have unsaved changes.",
        message: "Are you sure you want to leave?",
        confirmText: "Leave",
        cancelText: "Stay",
        accept: () => true,
        reject: () => false,
      });

      next(confirmed);
    } else {
      next();
    }
  });
}
