import type { RouteRecordRaw } from "vue-router";

export const routes = <RouteRecordRaw[]>[
  {
    path: "/settings",

    name: "settings",
    component: () => import("@web/features/Settings/SettingsContainer.vue"),
    redirect: () => {
      return {
        name: "my-account",
      };
    },
    children: [
      {
        path: "/:catchAll(.*)",
        redirect: { name: "my-account" },
      },
      {
        path: "",
        name: "settings-redirect",
        redirect: { name: "my-account" },
      },
      {
        path: "my-account",
        name: "my-account",
        component: () => import("./Personal/MyAccount.vue"),
      },
      {
        path: "notifications",
        name: "notifications",
        component: () => import("./Notifications/NotificationsOverview.vue"),
      },
      {
        path: "financials",
        name: "financials",
        component: () => import("./Subscription/SubscriptionView.vue"),
      },
      {
        path: "user-groups",
        name: "user-groups",
        component: () => import("./UserGroups/UserGroupsOverview.vue"),
      },
      {
        path: "sorts",
        name: "sorts",
        component: () => import("./Sorts/SortsOverview.vue"),
      },
      {
        path: "report-topics",
        name: "report-topics",
        component: () => import("./ReportTopics/ReportTopicOverview.vue"),
      },
      {
        path: "property-groups",
        name: "property-groups",
        component: () => import("./PropertyGroups/PropertyGroupOverview.vue"),
      },
      {
        path: "properties",
        name: "properties",
        component: () => import("./Properties/PropertyOverview.vue"),
      },
      {
        path: "locations",
        name: "locations",
        component: () => import("./Locations/LocationsPage.vue"),
      },
      {
        path: "automations",
        name: "automations",
        component: () => import("../Settings/Automation/AutomationOverview.vue"),
      },
      {
        path: "articles",
        name: "articles",
        component: () => import("./Articles/ArticlesOverview.vue"),
      },
      {
        path: "app-store/:clientId?/:appId?",
        name: "app-store",
        component: () => import("./AppStore/AppStoreOverview.vue"),
      },
      {
        path: "roles",
        name: "roles",
        component: () => import("./PermissionTemplateGroups/PermissionTemplateGroupsOverview.vue"),
      },
      {
        path: "users",
        name: "users",
        component: () => import("./Users/UsersOverview.vue"),
      },
    ],
  },
];

export default routes;
