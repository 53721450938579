import type { ContextVoterInterface } from "./composables/ContextVoter.interface";
import { PermissionAttributesEnum } from "@verbleif/lib";
import { ContextVoter } from "./composables/ContextVoter";

export class AppSubscriptionVoter extends ContextVoter implements ContextVoterInterface {
  public canCreate: ContextVoterInterface["canCreate"] = (item) => {
    return this._canCreate({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationAppsubscriptionCreateany,
      contextAttribute: PermissionAttributesEnum.ContextClientOperationAppsubscriptionCreate,
    });
  };

  public canRead: ContextVoterInterface["canRead"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationAppsubscriptionReadany,
      contextAttribute: PermissionAttributesEnum.ContextClientOperationAppsubscriptionRead,
    });
  };

  public canDelete: ContextVoterInterface["canDelete"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationAppsubscriptionDeleteany,
      contextAttribute: PermissionAttributesEnum.ContextClientOperationAppsubscriptionDelete,
    });
  };

  public canUpdate: ContextVoterInterface["canUpdate"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationAppsubscriptionUpdateany,
      contextAttribute: PermissionAttributesEnum.ContextClientOperationAppsubscriptionUpdate,
    });
  };

  public canCrud: ContextVoterInterface["canCrud"] = (item = {
    client: this.permissionService.defaultClient,
    location: this.permissionService.defaultLocation,
  }) => {
    return this._canCrud({
      item,
      globalAttribute: [
        PermissionAttributesEnum.GlobalOperationAppsubscriptionCreateany,
        PermissionAttributesEnum.GlobalOperationAppsubscriptionUpdateany,
        PermissionAttributesEnum.GlobalOperationAppsubscriptionDeleteany,
      ],
      contextAttribute: [
        PermissionAttributesEnum.ContextClientOperationAppsubscriptionCreate,
        PermissionAttributesEnum.ContextClientOperationAppsubscriptionUpdate,
        PermissionAttributesEnum.ContextClientOperationAppsubscriptionDelete,
      ],
    });
  };
}
