<script setup lang="ts">
import { type Component, computed } from "vue";
import { useI18n } from "vue-i18n";
import ExclamationCircle from "../../assets/exclamation-circle.svg?component";

interface Props {
  title?: string
  text?: string | ((errorCode?: number) => string | undefined)
  errorCode?: number
  errorMsg?: string
  iconComponent?: Component | null
  defaultStyleClasses?: string[]
}

const {
  title,
  errorCode,
  text,
  iconComponent = ExclamationCircle,
  defaultStyleClasses = ["p-6"],
} = defineProps<Props>();
const { t, te } = useI18n();
const renderedTitle = computed(() => {
  return title || (errorCode && errorCode === 403 ? t("base.error_403_title") : t("base.error"));
});

const renderedText = computed(() => {
  const propText = typeof text === "function" ? text(errorCode) : text;
  const errorText = errorCode ? te(`base.${errorCode}`) ? t(`base.${errorCode}`) : t("base.unknown_code", { errorCode }) : t("base.error_subtitle");

  if (text) {
    return propText;
  }

  if (errorCode) {
    return errorText;
  }

  return t("base.error_subtitle");
});
</script>

<template>
  <div
    class="flex w-full flex-col items-center justify-center gap-4 text-[--text] text-center"
    :class="defaultStyleClasses"
  >
    <div v-if="iconComponent !== null" class="icon">
      <slot name="icon">
        <component :is="iconComponent" />
      </slot>
    </div>
    <div v-if="renderedTitle !== ''" class="font-bold text-xl">
      <slot name="title">
        {{ renderedTitle }}
      </slot>
    </div>
    <div v-if="renderedText !== ''" class="text">
      <slot name="text">
        {{ renderedText }}
      </slot>
    </div>
  </div>
</template>
