import { useAuthStore, useConfigStore, useImpersonationStore, useLocationStore, useSharedStore } from "@verbleif/shared";
import router from "@web/core/plugin/router";
import { useSystemStore } from "@web/features/Store/useSystemStore";
import { Crisp } from "crisp-sdk-web";

function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function useLogout() {
  const authStore = useAuthStore();
  const { config } = useConfigStore();
  const systemStore = useSystemStore();
  const locationStore = useLocationStore();
  const impersonationStore = useImpersonationStore();
  const sharedStore = useSharedStore();

  async function logout(redirectToLogin = true, keepReturnUrl = false) {
    if (!config.value) {
      throw new Error("Config not set");
    }

    await router.push({ name: "logout" });

    await delay(2000);

    if (redirectToLogin) {
      await Promise.all([
        authStore.reset(keepReturnUrl),
        locationStore.reset(),
        impersonationStore.reset(),
        sharedStore.reset(),
      ]);
      try {
        Crisp.session.reset();
        Crisp.chat.hide();
      } catch (e) {
        console.error(e);
      }

      if (import.meta.env.VITE_SKIP_OAUTH === "true") {
        await router.push({ name: "login" }).then(() => {
          systemStore.reset();
        });
      } else {
        window.location.replace(`${config.value.loginUrl}/logout`);
      }
    }

    systemStore.reset();
  }

  return { logout };
}
