import type {
  ClientHydraItem,
  LocationHydraItem,
  SpaceHydraItem,
  UserHydraItem,
} from "@verbleif/lib";
import type { AuthResponse } from "@verbleif/shared";
import type { AxiosRequestConfig } from "axios";
import * as Sentry from "@sentry/vue";
import {
  api,
  getAll,
  useAuthStore,
  useLocationStore,
} from "@verbleif/shared";
import router from "@web/core/plugin/router";
import { Crisp } from "crisp-sdk-web";

export async function onAuthenticateSuccess(response: AuthResponse, isLogin: boolean = false): Promise<void> {
  const authStore = useAuthStore();
  const locationStore = useLocationStore();
  // Set tokens
  authStore.setToken(response.access_token);
  authStore.setRefreshToken(response.refresh_token, true);

  // Fetch and set user
  const user = await api.get<UserHydraItem>("/api/users/me", {
    _retry: false,
  } satisfies AxiosRequestConfig & { _retry: boolean } as any)
    .then(r => r.data);
  authStore.setUser(user);

  const clients = await getAll<ClientHydraItem>({
    url: "/clients",
    _retry: false,
  });
  locationStore.setClients(clients);

  const locations = await getAll<LocationHydraItem>({
    url: "/locations",
    _retry: false,
  });
  locationStore.setLocations(locations);

  const spaces = await getAll<SpaceHydraItem>({
    url: "/spaces",
    _retry: false,
  });
  locationStore.setSpaces(spaces);

  try {
    if (authStore.user.value) {
      if (authStore.user.value?.username && authStore.user.value?.username.includes("@")) {
        Crisp.user.setEmail(authStore.user.value?.username);
      }
      if (authStore.user.value?.name) {
        Crisp.user.setNickname(authStore.user.value?.name);
      }
      if (locationStore.selectedClient.value?.name) {
        Crisp.user.setCompany(locationStore.selectedClient.value?.name, {});
      }
      Crisp.session.setData({
        user_id: authStore.user.value?.id,
      });
    }
    Crisp.session.setSegments(["web"], false);
    Crisp.chat.show();
  } catch (e) {
    Sentry.captureException(e);
    console.error("Crisp:", e);
  }

  if (isLogin) {
    const { returnUrl } = useAuthStore();

    if (returnUrl.value) {
      await router.push(returnUrl.value);
    } else {
      await router.push({ name: "tasks" });
    }
  }
}
