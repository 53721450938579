import type { HydraItem } from "../Api";
import { StorageSerializers, useStorage } from "@vueuse/core";
import { EventSourcePolyfill } from "event-source-polyfill";
import { getCurrentInstance, onBeforeUnmount, watch } from "vue";

export const disableMercure = useStorage<boolean>("disableMercure", false, undefined, {
  serializer: StorageSerializers.boolean,
});

interface MercureInit<T> {
  topic?: string | null
  onAddOrUpdate?: (message: HydraItem<T>) => any
  onDelete?: (message: HydraItem<T>) => any
  hubUrlGetter: () => string | null
  mercureTokenGetter: () => string | null
  refreshMercureToken: () => Promise<void>
}

export function useMercure<T>({
  hubUrlGetter,
  mercureTokenGetter,
  refreshMercureToken,
  topic = null,
  onAddOrUpdate,
  onDelete,
}: MercureInit<T>) {
  let eventSource: EventSourcePolyfill | null = null;
  let lastEvent: string | null = null;
  let retry = false;

  function isConnected() {
    return eventSource !== null;
  }

  async function connect(passedTopic: string) {
    const mercureToken = mercureTokenGetter();
    if (!mercureToken) {
      console.debug("[Mercure]: no token, refreshing...");
      await refreshMercureToken();
    } else {
      console.debug("[Mercure]: token exists.");
    }
    const hubUrl = hubUrlGetter();
    if (!hubUrl) {
      console.warn("Mercure: hubUrl is not set or empty. Not connecting.");
      return;
    }

    const url = new URL(`${hubUrl}/.well-known/mercure`, window.origin);
    url.searchParams.append("topic", `/api${passedTopic}`);

    if (lastEvent) {
      url.searchParams.set("lastEventId", lastEvent);
    }

    const config = {
      headers: {
        Authorization: `Bearer ${mercureTokenGetter()}`,
      },
    };
    if (eventSource) {
      console.debug("Mercure: closing old connection.");
      eventSource.close();
    }
    eventSource = new EventSourcePolyfill(url.toString(), config);
    eventSource.onerror = e => onError(e, passedTopic);
    eventSource.onmessage = onMessage;
    eventSource.onopen = () => {
      console.debug("Mercure: connected.");
      retry = false;
    };
    console.debug("Mercure: connecting...");
  }

  function onError(e: any, passedTopic: string) {
    console.debug("Mercure: onError()", e, retry);
    if (e?.status !== 401 || retry) {
      return;
    }
    refreshMercureToken().then(() => {
      if (
        !eventSource
        || (eventSource && eventSource.readyState === EventSourcePolyfill.CLOSED)
      ) {
        connect(passedTopic).then();
        retry = true;
      }
    });
  }

  function close() {
    if (!isConnected()) {
      return false;
    }
    console.debug("Mercure: closing connection.");
    return eventSource?.close();
  }

  function onMessage(event: any) {
    console.log(event, typeof event);
    lastEvent = event.lastEventId;
    if (!event.data) {
      return;
    }

    const parsed = JSON.parse(event.data);
    const keys = Object.keys(parsed);

    if (keys.length <= 2) {
      return onDelete && onDelete(parsed);
    }
    return onAddOrUpdate && onAddOrUpdate(parsed);
  }

  // Only register onBeforeUnmount if we're in a component context
  if (getCurrentInstance()) {
    onBeforeUnmount(() => {
      close();
    });
  }

  // TODO: We temp disabled mercure
  if (!disableMercure.value) {
    if (topic) {
      connect(topic);
    }
  }
  watch(disableMercure, (newVal) => {
    if (newVal) {
      console.log("Mercur disabled again");
      close();
    }
  });

  return {
    close,
    isConnected,
    connect,
  };
}
