<script setup lang="ts">
import type { TimeEntriestopSchema } from "./time_entry.stop.schema";
import { toTypedSchema } from "@vee-validate/yup";
import { useField, useForm } from "vee-validate";
import { useI18n } from "vue-i18n";
import { timeEntriestopSchema } from "./time_entry.stop.schema";
import { useActiveTimeEntry } from "./useActiveTimeEntry";

const TRANSLATION_KEY = "tasks.TimeEntry";
const visible = ref(false);
const { t } = useI18n();
const { setActiveTimeEntryToEnded, currentActiveTimeEntry } = useActiveTimeEntry();

const { handleSubmit, resetForm, meta } = useForm<TimeEntriestopSchema>({
  validationSchema: toTypedSchema(timeEntriestopSchema(t)),
  initialValues: {
    notes: "",
  },
});

const loading = ref(false);
const error = ref<string | undefined>(undefined);
const { onError } = useServerErrorHandler();
const onHideRef = ref<(() => void) | null>(null);

async function show(onHide?: () => void) {
  visible.value = true;
  resetForm();
  onHideRef.value = onHide || null;
}

function hide() {
  visible.value = false;
  error.value = undefined;
  resetForm();
  onHideRef.value?.();
  onHideRef.value = null;
}

defineExpose({
  show,
  hide,
});

const onSubmit = handleSubmit(async (values) => {
  if (!currentActiveTimeEntry.value?.task) {
    return;
  }

  try {
    loading.value = true;
    await setActiveTimeEntryToEnded(values);
    hide();
  } catch (e) {
    error.value = onError(e);
  } finally {
    loading.value = false;
  }
});

const { value: notes, errorMessage: notesErrorMessage } = useField<TimeEntriestopSchema["notes"]>("notes");
</script>

<template>
  <VDialog
    id="time-track-stop-dialog"
    :visible="visible"
    mask
    :is-dirty="() => meta.dirty"
    @close="hide"
  >
    <template #title>
      <strong class="text-lg text-gray-800 dark:text-gray-200">{{ t(`${TRANSLATION_KEY}.stop.title`) }}</strong>
    </template>
    <template #content>
      <div class="flex flex-col gap-6 justify-center items-center p-6">
        <div class="flex flex-col items-center gap-4">
          <FontAwesomeIcon
            :icon="['far', 'clock-rotate-left']"
            class="text-4xl text-red-500 dark:text-red-400"
          />
          <p class="text-base text-center text-gray-700 dark:text-gray-200">
            {{ t(`${TRANSLATION_KEY}.stop.explain`) }}
          </p>
        </div>

        <div class="w-full">
          <VTextArea
            v-model="notes"
            :label-top="t(`${TRANSLATION_KEY}.fields.notes.label`)"
            :placeholder="t(`${TRANSLATION_KEY}.fields.notes.placeholder`)"
            :error-message="notesErrorMessage || error"
            :invalid="!!notesErrorMessage || !!error"
            :loading="loading"
            multiline
            rows="4"
            name="notes"
            resize="is-vertical"
            class="w-full"
          />
        </div>
      </div>
    </template>
    <template #footer="{ close }">
      <div class="w-full">
        <VDivider class="w-full !mb-6" />
        <div class="flex justify-between">
          <VButton rounded class="is-gray" @click="close">
            {{ t("base.cancel") }}
          </VButton>
          <VButton rounded class="is-danger" :disabled="loading" @click="onSubmit">
            {{ t(`${TRANSLATION_KEY}.stop.action`) }}
          </VButton>
        </div>
      </div>
    </template>
  </VDialog>
</template>
