import type { TaskHydraItem } from "@verbleif/lib";
import type { PermissionService } from "../PermissionService";
import type { TaskVoter } from "./TaskVoter";
import type { TaskEntityVote } from "./voters.helpers";
import { PermissionAttributesEnum } from "@verbleif/lib";
import { ContextVoter } from "./composables/ContextVoter";

const taskFieldMapping: Record<
  "blocking"
  | "deadline_at"
  | "description"
  | "inside"
  | "priority"
  | "property"
  | "quantity"
  | "sort"
  | "status"
  | "status.to_checked"
  | "status.to_finished_or_checked_future_deadline_at",
  {
    create: PermissionAttributesEnum | null
    update: PermissionAttributesEnum
  }
> = {
  "blocking": {
    create: PermissionAttributesEnum.ContextFieldTaskCreateBlocking,
    update: PermissionAttributesEnum.ContextFieldTaskUpdateBlocking,
  },
  "deadline_at": {
    create: PermissionAttributesEnum.ContextFieldTaskCreateDeadlineat,
    update: PermissionAttributesEnum.ContextFieldTaskUpdateDeadlineat,
  },
  "description": {
    create: null,
    update: PermissionAttributesEnum.ContextFieldTaskUpdateDescription,
  },
  "inside": {
    create: PermissionAttributesEnum.ContextFieldTaskCreateInside,
    update: PermissionAttributesEnum.ContextFieldTaskUpdateInside,
  },
  "priority": {
    create: PermissionAttributesEnum.ContextFieldTaskCreatePriority,
    update: PermissionAttributesEnum.ContextFieldTaskUpdatePriority,
  },
  "property": {
    create: PermissionAttributesEnum.ContextFieldTaskCreateProperty,
    update: PermissionAttributesEnum.ContextFieldTaskUpdateProperty,
  },
  "quantity": {
    create: PermissionAttributesEnum.ContextFieldTaskCreateQuantity,
    update: PermissionAttributesEnum.ContextFieldTaskUpdateQuantity,
  },
  "sort": {
    create: null,
    update: PermissionAttributesEnum.ContextFieldTaskUpdateSort,
  },
  "status": {
    create: null,
    update: PermissionAttributesEnum.ContextFieldTaskUpdateStatus,
  },
  "status.to_checked": {
    create: null,
    update: PermissionAttributesEnum.ContextFieldTaskUpdateStatusTochecked,
  },
  "status.to_finished_or_checked_future_deadline_at": {
    create: null,
    update: PermissionAttributesEnum.ContextFieldTaskUpdateStatusTofinishedorcheckedfuturedeadlineat,
  },
};

export type TaskFieldAttribute = keyof typeof taskFieldMapping;

export class TaskFieldVoter extends ContextVoter {
  public taskVoter: TaskVoter;

  constructor(permissionService: PermissionService, taskVoter: TaskVoter) {
    super(permissionService);
    this.taskVoter = taskVoter;
  }

  public hasField = (entity: TaskEntityVote | null, operation: "create" | "update", field: TaskFieldAttribute): boolean => {
    if (taskFieldMapping[field] === undefined || taskFieldMapping[field][operation] === undefined) {
      return false;
    }

    const attribute = taskFieldMapping[field][operation];

    if (attribute === null) {
      return true;
    }

    if (entity) {
      if (this.permissionService.getGlobalPermissionForUser({
        attribute: operation === "create"
          ? PermissionAttributesEnum.GlobalFieldTaskCreateany
          : PermissionAttributesEnum.GlobalFieldTaskUpdateany,
      })) {
        return true;
      }
      return this.taskVoter.hasAccessToAttributeViaTaskRelations(entity as TaskHydraItem, attribute);
    }

    return false;
  };
}
