import type { PermissionService } from "../PermissionService";
import type { TaskVoter } from "./TaskVoter";
import type { TaskEntityVote } from "./voters.helpers";
import { PermissionAttributesEnum } from "@verbleif/lib";
import { ContextVoter } from "./composables/ContextVoter";

export class TaskTimeEntryVoter extends ContextVoter {
  public taskVoter: TaskVoter;

  constructor(permissionService: PermissionService, taskVoter: TaskVoter) {
    super(permissionService);
    this.taskVoter = taskVoter;
  }

  public canCreate = (task: TaskEntityVote | null): boolean => {
    if (
      this.permissionService.getGlobalPermissionForUser({
        attribute:
          PermissionAttributesEnum.GlobalOperationTasktimeentryCreateany,
      })
    ) {
      return true;
    }

    return task
      ? this.taskVoter.hasAccessToAttributeViaTaskRelations(
          task,
          PermissionAttributesEnum.ContextOperationTasktimeentryCreate,
        )
      : false;
  };

  public canRead = (): boolean => {
    if (
      this.permissionService.getGlobalPermissionForUser({
        attribute: PermissionAttributesEnum.GlobalOperationTasktimeentryReadany,
      })
    ) {
      return true;
    }

    return false;
  };

  public canDelete = (task: TaskEntityVote | null): boolean => {
    if (
      this.permissionService.getGlobalPermissionForUser({
        attribute:
          PermissionAttributesEnum.GlobalOperationTasktimeentryDeleteany,
      })
    ) {
      return true;
    }

    return task
      ? this.taskVoter.hasAccessToAttributeViaTaskRelations(
          task,
          PermissionAttributesEnum.ContextOperationTasktimeentryDelete,
        )
      : false;
  };

  public canUpdate = (task: TaskEntityVote | null): boolean => {
    if (
      this.permissionService.getGlobalPermissionForUser({
        attribute:
          PermissionAttributesEnum.GlobalOperationTasktimeentryUpdateany,
      })
    ) {
      return true;
    }

    return task
      ? this.taskVoter.hasAccessToAttributeViaTaskRelations(
          task,
          PermissionAttributesEnum.ContextOperationTasktimeentryUpdate,
        )
      : false;
  };
}
