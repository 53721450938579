import type { RouteRecordRaw } from "vue-router";
import { PermissionAttributesEnum } from "@verbleif/lib";
import { isFocusedOnTaskShortcut } from "./Overview/useTaskList";

export enum TaskListType {
  TYPE_TODAY = "td",
  TYPE_ALL_TODAY = "atd",
  TYPE_PER_PROPERTY = "po",
  TYPE_ALL = "a",
  TYPE_ALL_UNTIL_TODAY = "atud",
  TYPE_OPEN_UNTIL_TODAY = "oto",
  TYPE_CHANGEOVER_DAY = "co",
  TYPE_ASSIGNED_TO_ME = "asg",
  TYPE_ROUTE = "ro",
  TYPE_UNASSIGNED = "uasg",
  TYPE_READY = "rd",
  TYPE_FINISHED = "fn",
  TYPE_USER_GROUP = "ug",
  TYPE_USER = "u",
  TYPE_TEMPLATE = "tp",
}

export const shortcutTaskListTypeMap = {
  [PermissionAttributesEnum.ContextTaskshortcutAlltasks]: TaskListType.TYPE_ALL,
  [PermissionAttributesEnum.ContextTaskshortcutAlltaskstoday]: TaskListType.TYPE_ALL_TODAY,
  [PermissionAttributesEnum.ContextTaskshortcutAlltasksuntiltoday]: TaskListType.TYPE_ALL_UNTIL_TODAY,
  [PermissionAttributesEnum.ContextTaskshortcutChecked]: TaskListType.TYPE_READY,
  [PermissionAttributesEnum.ContextTaskshortcutFinished]: TaskListType.TYPE_FINISHED,
  [PermissionAttributesEnum.ContextTaskshortcutMytaskstoday]: TaskListType.TYPE_TODAY,
  [PermissionAttributesEnum.ContextTaskshortcutMytasksuntiltoday]: TaskListType.TYPE_OPEN_UNTIL_TODAY,
  [PermissionAttributesEnum.ContextTaskshortcutPerproperty]: TaskListType.TYPE_PER_PROPERTY,
  [PermissionAttributesEnum.ContextTaskshortcutWalkroute]: TaskListType.TYPE_ROUTE,
  [PermissionAttributesEnum.ContextTaskshortcutAssignedtome]: TaskListType.TYPE_ASSIGNED_TO_ME,
  [PermissionAttributesEnum.ContextTaskshortcutUnassigned]: TaskListType.TYPE_UNASSIGNED,
};
export const TASK_LIST_FALLBACK_TYPE = TaskListType.TYPE_TODAY;

const routes = <RouteRecordRaw[]>[
  {
    path: "/t",
    name: "tasks",
    component: () => import("@web/features/Tasks/TasksContainer.vue"),
    redirect: (props) => {
      return {
        name: "task-list",
        params: { type: TASK_LIST_FALLBACK_TYPE, ...props?.params },
      };
    },
    children: [
      {
        path: "/:catchAll(.*)",
        redirect: {
          name: "task-list",
          params: { type: TASK_LIST_FALLBACK_TYPE },
        },
      },
      {
        path: "li/:type/:id?/:taskId?",
        name: "task-list",
        component: () => import("./Overview/TasksView.vue"),
        beforeEnter: (to, _, next) => {
          const type = to.params.type as TaskListType;
          const { id, taskId } = to.params;

          if (!Object.values(TaskListType).includes(type)) {
            return next({
              name: "task-list",
              params: { type: TASK_LIST_FALLBACK_TYPE, taskId },
            });
          }

          const focusedShortcut = isFocusedOnTaskShortcut.value;
          if (focusedShortcut) {
            const typeFromShortcut = shortcutTaskListTypeMap[focusedShortcut as keyof typeof shortcutTaskListTypeMap];

            if (type !== typeFromShortcut) {
              return next({
                name: "task-list",
                params: {
                  type: typeFromShortcut ?? TASK_LIST_FALLBACK_TYPE,
                  id,
                  taskId,
                },
              });
            }
          }

          if (id && !taskId && typeof id === "string" && id.startsWith("t")) {
            return next({
              name: "task-list",
              params: {
                type,
                taskId: id,
                id: undefined,
              },
            });
          }

          return next();
        },
      },
    ],
  },
];

export default routes;
