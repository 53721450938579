import type { ReportHydraItem, TaskHydraItem } from "@verbleif/lib";
import type { PermissionService } from "../PermissionService";
import type { ReportVoter } from "./ReportVoter";
import type { TaskVoter } from "./TaskVoter";
import type { EntityVote } from "./voters.helpers";
import { PermissionAttributesEnum } from "@verbleif/lib";
import { ContextVoter } from "./composables/ContextVoter";

export class MediaObjectMetaDataVoter extends ContextVoter {
  public taskVoter: TaskVoter;
  public reportVoter: ReportVoter;

  constructor(permissionService: PermissionService, taskVoter: TaskVoter, reportVoter: ReportVoter) {
    super(permissionService);
    this.taskVoter = taskVoter;
    this.reportVoter = reportVoter;
  }

  public canCreate = (entity: EntityVote | null): boolean => {
    if (entity) {
      if (entity["@type"] === "Task") {
        if (this.permissionService.getGlobalPermissionForUser({
          attribute: PermissionAttributesEnum.GlobalOperationMediaobjecttaskmetadataCreateany,
        })) {
          return true;
        }

        return this.taskVoter.hasAccessToAttributeViaTaskRelations(entity as TaskHydraItem, PermissionAttributesEnum.ContextOperationMediaobjecttaskmetadataCreate);
      }
      if (entity["@type"] === "Report") {
        if (this.permissionService.getGlobalPermissionForUser({
          attribute: PermissionAttributesEnum.GlobalOperationMediaobjectreportmetadataCreateany,
        })) {
          return true;
        }
        return this.reportVoter.hasAccessToAttributeViaReportRelations(entity as ReportHydraItem, PermissionAttributesEnum.ContextOperationMediaobjecttaskmetadataCreate);
      }
    }

    return false;
  };

  public canRead = (): boolean => {
    return true;
  };

  public canDelete = (entity: EntityVote | null): boolean => {
    if (entity) {
      if (entity["@type"] === "Task") {
        if (this.permissionService.getGlobalPermissionForUser({
          attribute: PermissionAttributesEnum.GlobalOperationMediaobjecttaskmetadataDeleteany,
        })) {
          return true;
        }
        return this.taskVoter.hasAccessToAttributeViaTaskRelations(entity as TaskHydraItem, PermissionAttributesEnum.ContextOperationMediaobjecttaskmetadataDelete);
      }
      if (entity["@type"] === "Report") {
        if (this.permissionService.getGlobalPermissionForUser({
          attribute: PermissionAttributesEnum.GlobalOperationMediaobjectreportmetadataDeleteany,
        })) {
          return true;
        }
        return this.reportVoter.hasAccessToAttributeViaReportRelations(entity as ReportHydraItem, PermissionAttributesEnum.ContextOperationMediaobjecttaskmetadataDelete);
      }
    }

    return false;
  };
}
