// File: LazyLoadScroll.ts
import type { ComputedRef, Ref } from "vue";
import { computed, nextTick, onBeforeUnmount, watch } from "vue";

export enum ScrollDirection {
  TOP = "top",
  BOTTOM = "bottom",
}

export function useLazyLoadScroll(
  scrollableTarget: Ref<HTMLElement | null>,
  loadMoreHook: (options: object) => Promise<void> | void,
  hasNextPage: Ref<boolean> | ComputedRef<boolean>,
  isLoading: Ref<boolean>,
  direction: ScrollDirection = ScrollDirection.BOTTOM,
) {
  const shouldLoadMore = computed(() => {
    if (isLoading.value) {
      return false;
    }
    return hasNextPage.value;
  });

  async function handleScroll(e: Event) {
    if (!e.target) {
      console.error("Cannot handleScroll since the passed event has no target.");
      return;
    }
    const target = e.target as HTMLElement;
    let almostAtEdge = false;

    if (direction === ScrollDirection.BOTTOM) {
      const scrollState = target.scrollTop + target.getBoundingClientRect().height;
      const partialScrollHeight = target.scrollHeight / 3;
      const loadOffset = partialScrollHeight < 200 ? 200 : partialScrollHeight;
      const loadItemsAt = target.scrollHeight - loadOffset;
      almostAtEdge = scrollState >= loadItemsAt;
    } else if (direction === ScrollDirection.TOP) {
      const partialScrollHeight = target.scrollHeight / 3;
      const loadOffset = partialScrollHeight < 200 ? 200 : partialScrollHeight;
      almostAtEdge = target.scrollTop <= loadOffset;
    }

    if (almostAtEdge && shouldLoadMore.value) {
      if (direction === ScrollDirection.TOP) {
        // Record the current scroll position and height before loading new items
        const oldScrollHeight = target.scrollHeight;
        const oldScrollTop = target.scrollTop;
        // Call the loadMoreHook (supports async functions)
        const result = loadMoreHook({ scroll: true });
        if (result && typeof result.then === "function") {
          await result;
        }
        // Wait for DOM update
        await nextTick();
        const newScrollHeight = target.scrollHeight;
        // Adjust scrollTop so that the user stays at the bottom of the new data
        target.scrollTop = oldScrollTop + (newScrollHeight - oldScrollHeight);
      } else {
        // For bottom scrolling, simply trigger loadMoreHook
        loadMoreHook({ scroll: true });
      }
    }
  }

  watch(scrollableTarget, (newTarget, oldTarget) => {
    if (oldTarget) {
      oldTarget.removeEventListener("scroll", handleScroll);
    }
    if (newTarget) {
      newTarget.addEventListener("scroll", handleScroll);
    }
  });

  onBeforeUnmount(() => {
    if (scrollableTarget.value) {
      scrollableTarget.value.removeEventListener("scroll", handleScroll);
    }
  });

  return {
    shouldLoadMore,
  };
}
