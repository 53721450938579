import type { MercureProgress } from "@verbleif/lib";
import {
  MercureProgressSubjectEnum,
  MercureProgressTypeEnum,
  NotificationTypeEnum,
  useMercure,
} from "@verbleif/lib";
import { useI18n } from "@verbleif/lib/src/translations/i18n";
import { useConfigStore, useLocationStore } from "@verbleif/shared";
import { useMercureState } from "@web/composables/useMercureState";
import { watchEffect } from "vue";

const MAX_PROGRESS_PERCENTAGE = 100;

export enum ImportEntityEnum {
  RENTABLE = "rentable",
  RENTABLE_IDENTITY = "rentable_identity",
  RENTABLE_TYPE = "rentable_type",
  FACILITY = "facility",
  USER = "user",
  EXECUTOR = "executor",
  TODO_LIST = "todo_list",
  TODO = "todo",
  RESERVATION = "reservation",
  PRODUCT = "product",
  PLACE = "place",
}

const MercureSubjectToNotificationType: Record<MercureProgressSubjectEnum, NotificationTypeEnum | null> = {
  [MercureProgressSubjectEnum.SYNC_BOOKING_EXPERTS]: NotificationTypeEnum.TYPE_SYNC_BEX,
  [MercureProgressSubjectEnum.SYNC_CAMPING_CARE]: NotificationTypeEnum.TYPE_SYNC_CAMPING_CARE,
  [MercureProgressSubjectEnum.SYNC_RECRANET]: null,
  [MercureProgressSubjectEnum.SYNC_TOMMY]: null,
  [MercureProgressSubjectEnum.BULK_ENTITY_UPDATE]: null,
};

export interface ImportMercureProgress extends MercureProgress {
  data: {
    startTime: number // inherited
    maxSteps: number // inherited
    step: number // inherited
    percentage: number // inherited
    elapsed: number // inherited
    estimated: number // inherited
    remaining: number // inherited
    totalItems: number
    pageSize: number
    entity: ImportEntityEnum
  }
}

export default function () {
  const { t } = useI18n();
  const locationStore = useLocationStore();
  const configStore = useConfigStore();
  const mercureState = useMercureState();
  function secondsToHumanReadable(inputSeconds: number) {
    const days = Math.floor((inputSeconds % 31536000) / 86400);
    const hours = Math.floor(((inputSeconds % 31536000) % 86400) / 3600);
    const minutes = Math.floor((((inputSeconds % 31536000) % 86400) % 3600) / 60);
    const seconds = (((inputSeconds % 31536000) % 86400) % 3600) % 60;

    let humanReadableString = `${seconds} ${t("base.seconds", seconds)}`;

    if (minutes > 0) {
      humanReadableString = `${minutes} ${t(
        "base.minutes",
        minutes,
      )} - ${humanReadableString}`;
    }

    if (hours > 0) {
      humanReadableString = `${hours} ${t(
        "base.hours",
        hours,
      )} - ${humanReadableString}`;
    }

    if (days > 0) {
      humanReadableString = `${days} ${t(
        "base.days",
        days,
      )} - ${humanReadableString}`;
    }

    return humanReadableString;
  }

  const mercure = useMercure<ImportMercureProgress>({
    hubUrlGetter: () => configStore.config.value?.mercureUrl || "",
    refreshMercureToken: mercureState.refreshMercureToken,
    mercureTokenGetter: () => mercureState.mercureToken.value || "",
    onAddOrUpdate: async (mercureProgress) => {
      console.log(mercureProgress);
      if (mercureProgress.type === MercureProgressTypeEnum.UPDATE) {
        const type = MercureSubjectToNotificationType[mercureProgress.subject];
        if (!type) {
          return;
        }
        notifications.addStaticNotification({
          iri: mercureProgress["@id"],
          type,
          leftFooterText: t(
            `notifications.${mercureProgress.subject}.label`,
            {
              step: mercureProgress.data.step,
              maxSteps: mercureProgress.data.maxSteps,
              percentage: mercureProgress.data.percentage,
              remainingHuman: secondsToHumanReadable(
                mercureProgress.data.remaining,
              ),
            },
          ),
          progressData: {
            current: mercureProgress.data.percentage,
            max: MAX_PROGRESS_PERCENTAGE,
          },
          title: t(
            `notifications.${mercureProgress.subject}.title`,
            {
              locationName: locationStore.locations.value[mercureProgress["@id"]]?.name,
            },
          ),
          body: t(
            `notifications.${mercureProgress.subject}.${mercureProgress.data.entity}`,
          ),
        });
      } else if (mercureProgress.type === MercureProgressTypeEnum.FINISHED) {
        notifications.hideStaticNotification(mercureProgress["@id"]);
        locationStore.setImportRunningForLocationIri(mercureProgress["@id"], false);
      }
    },
  });

  watchEffect(() => {
    const clientId = locationStore.selectedClient.value?.id;
    if (!clientId) {
      return;
    }

    //* This was role manager check

    if (!false) {
      return;
    }
    if (!locationStore.isAnyImportRunning.value) {
      mercure.close();
      return;
    }
    if (mercure.isConnected()) {
      return;
    }

    console.log("Import is running, connect mercure");
    mercure.connect(`/clients/${clientId}/progress`);
  });
}
