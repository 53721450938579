<script setup lang="ts">
import type { EnvironmentKey } from "@web/features/ConfigOverride/configPresets";
import { disableMercure } from "@verbleif/lib";
import { useAuthStore, useConfigStore, useLocationStore } from "@verbleif/shared";
import { useMagicKeys, whenever } from "@vueuse/core";
import { config } from "@web/features/ConfigOverride/configPresets";
import { isAdmin } from "@web/features/Permissions";
import useSystemStore from "@web/features/Store/useSystemStore.ts";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { toast } from "vue-sonner";
import { configOverrideDialogVisible } from "./configOverrideDialogRef";

import CustomUrlDialog from "./CustomUrlDialog.vue";

const { loading: initialLoading = false } = defineProps<{
  loading?: boolean
}>();

const emit = defineEmits<{
  (e: "close"): void
}>();

const loading = ref(initialLoading);

const { Ctrl_Shift_D } = useMagicKeys();
whenever(Ctrl_Shift_D, () => {
  configOverrideDialogVisible.value = true;
});

const configStore = useConfigStore();
const authStore = useAuthStore();
const systemStore = useSystemStore();
const locationStore = useLocationStore();
const { t } = useI18n();

async function setPreset(presetName: EnvironmentKey) {
  if (loading.value) {
    return;
  }
  loading.value = true;
  try {
    const preset = config[presetName];
    if (!preset) {
      throw new Error(`Preset not found: ${presetName}`);
    }

    configStore.setOverrideConfig({ presetName, ...preset });
    toast.info(t("settings.configOverride.overrideApplied"));
  } finally {
    loading.value = false;
  }
}

function toggleMercure() {
  if (loading.value) {
    return;
  }
  loading.value = true;
  try {
    disableMercure.value = !disableMercure.value;
    toast.info(t(`settings.configOverride.${disableMercure.value ? "disableMercure" : "enableMercure"}`));
  } finally {
    loading.value = false;
  }
}

async function reset() {
  if (loading.value) {
    return;
  }
  loading.value = true;
  try {
    configStore.reset();
    // systemStore.reset();
    // locationStore.reset();
    // authStore.reset();
    toast.info(t("settings.configOverride.resetSuccessful"));
  } finally {
    loading.value = false;
  }
}

const configOverrideDialog = ref(false);

function closeDialog() {
  emit("close");
}
</script>

<template>
  <VDialog
    id="config-override-dialog"
    v-model:visible="configOverrideDialogVisible"
    mask
    size="xlarge"
    @close="closeDialog"
  >
    <template #title>
      <span class="dark:text-white text-lg font-bold">{{ t("settings.configOverride.title") }}</span>
    </template>
    <template #content>
      <!-- Environment Presets Section - Full Width -->
      <div class="mb-3">
        <div class="flex flex-col gap-2 bg-gray-50 dark:bg-gray-900 rounded-lg p-4">
          <div class="text-sm font-medium text-gray-800 dark:text-gray-300">
            {{ t("settings.configOverride.environmentPresets") }}
          </div>
          <div class="flex flex-wrap gap-2">
            <VButton
              :variant="!!configStore.config.value?.presetName ? 'is-info is-outlined' : 'is-info'"
              size="is-small"
              :loading="loading"
              @click="() => configOverrideDialog = true"
            >
              {{ t("settings.configOverride.custom") }}
            </VButton>
            <VButton
              :variant="configStore.config.value?.presetName === 'localhost' ? 'is-primary' : 'is-primary is-outlined'"
              size="is-small"
              :loading="loading"
              @click="() => setPreset('localhost')"
            >
              {{ t("settings.configOverride.localhost") }}
            </VButton>
            <VButton
              :variant="configStore.config.value?.presetName === 'testing' ? 'is-primary' : 'is-primary is-outlined'"
              size="is-small"
              :loading="loading"
              @click="() => setPreset('testing')"
            >
              {{ t("settings.configOverride.dev") }}
            </VButton>
            <VButton
              :variant="configStore.config.value?.presetName === 'staging' ? 'is-warning' : 'is-warning is-outlined'"
              size="is-small"
              :loading="loading"
              @click="() => setPreset('staging')"
            >
              {{ t("settings.configOverride.staging") }}
            </VButton>
            <VButton
              :variant="configStore.config.value?.presetName === 'production' ? 'is-danger' : 'is-danger is-outlined'"
              size="is-small"
              :loading="loading"
              @click="() => setPreset('production')"
            >
              {{ t("settings.configOverride.production") }}
            </VButton>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <!-- Admin Section -->
        <div class="bg-gray-50 dark:bg-gray-900 rounded-lg p-4">
          <div class="flex flex-col gap-3">
            <div class="text-sm font-medium text-gray-800 dark:text-gray-300">
              {{ t("settings.configOverride.admin") }}
            </div>
            <div class="flex flex-col gap-3">
              <div class="flex items-center gap-2 px-3 py-2 rounded-md bg-gray-100 dark:bg-gray-800">
                <FontAwesomeIcon
                  :icon="['fas', 'user-shield']"
                  class="text-primary-500 w-[18px] h-[18px]"
                  size="lg"
                />
                <span class="font-medium text-gray-800 dark:text-gray-300 text-sm">{{ t("settings.configOverride.admin_status") }}</span>
                <div class="flex-1" />
                <span
                  class="px-2 py-0.5 rounded-md text-xs font-medium"
                  :class="isAdmin ? 'bg-success-100 text-success-700 dark:bg-success-900 dark:text-success-300' : 'bg-gray-200 text-gray-700 dark:bg-gray-700 dark:text-gray-300'"
                >
                  {{ isAdmin ? t("settings.configOverride.admin_enabled") : t("settings.configOverride.admin_disabled") }}
                </span>
              </div>
              <div class="flex items-center gap-2 px-3 py-2 rounded-md bg-gray-100 dark:bg-gray-800">
                <FontAwesomeIcon
                  :icon="['far', 'bug']"
                  class="text-primary-500 w-[18px] h-[18px]"
                  size="lg"
                />
                <span class="font-medium text-gray-800 dark:text-gray-300 text-sm">{{ t("settings.configOverride.debugMode") }}</span>
                <div class="flex-1" />
                <VCheckbox
                  v-model="configStore.debugMode.value"
                  name="debugMode"
                  :value="configStore.debugMode.value"
                  :true-value="true"
                  :false-value="false"
                  class="dark:text-gray-300"
                  @update:model-value="configStore.debugMode.value = $event as boolean"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Auth Store Section -->
        <div class="bg-gray-50 dark:bg-gray-900 rounded-lg p-4">
          <div class="flex flex-col gap-3">
            <div class="text-sm font-medium text-gray-800 dark:text-gray-300">
              {{ t("settings.configOverride.authStore") }}
            </div>
            <div class="flex flex-col gap-3">
              <div class="flex">
                <VButton
                  variant="is-danger is-outlined"
                  size="is-small"
                  icon-left="undo"
                  :loading="loading"
                  @click="() => authStore.reset()"
                >
                  {{ t("settings.configOverride.reset") }}
                </VButton>
              </div>
              <div class="flex">
                <VButton
                  variant="is-danger is-outlined"
                  size="is-small"
                  icon-left="undo"
                  :loading="loading"
                  @click="() => authStore.token.value = '123'"
                >
                  {{ t("settings.configOverride.fake_token") }}
                </VButton>
              </div>
              <div class="flex">
                <VButton
                  variant="is-danger is-outlined"
                  size="is-small"
                  icon-left="undo"
                  :loading="loading"
                  @click="() => authStore.refreshToken.value = '123'"
                >
                  {{ t("settings.configOverride.fake_refresh_token") }}
                </VButton>
              </div>
              <VCheckbox
                name="hasToken"
                :value="authStore.hasToken.value"
                :model-value="authStore.hasToken.value"
                :label-right="t('settings.configOverride.hasToken')"
                :disabled="true"
                class="dark:text-gray-300"
              />
              <VCheckbox
                name="isAuthenticated"
                :value="authStore.isAuthenticated.value"
                :model-value="authStore.isAuthenticated.value"
                :label-right="t('settings.configOverride.isAuthenticated')"
                :disabled="true"
                class="dark:text-gray-300"
              />
              <VCheckbox
                name="isRemembered"
                :value="authStore.isRemembered.value"
                :model-value="authStore.isRemembered.value"
                :label-right="t('settings.configOverride.isRemembered')"
                :disabled="true"
                class="dark:text-gray-300"
              />
              <div class="flex flex-col gap-2 text-sm text-gray-800 dark:text-gray-300">
                <span>{{ t("settings.configOverride.returnUrl") }}: {{ authStore.returnUrl.value || "-" }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Location Store Section -->
        <div class="bg-gray-50 dark:bg-gray-900 rounded-lg p-4">
          <div class="flex flex-col gap-3">
            <div class="text-sm font-medium text-gray-800 dark:text-gray-300">
              {{ t("settings.configOverride.locationStore") }}
            </div>
            <div class="flex flex-col gap-3">
              <div class="flex">
                <VButton
                  variant="is-danger is-outlined"
                  size="is-small"
                  icon-left="undo"
                  :loading="loading"
                  @click="() => locationStore.reset()"
                >
                  {{ t("settings.configOverride.reset") }}
                </VButton>
              </div>
              <VCheckbox
                v-model="locationStore.hasMultipleLocations.value"
                name="hasMultipleLocations"
                :value="locationStore.hasMultipleLocations.value"
                :label-right="t('settings.configOverride.hasMultipleLocations')"
                :disabled="true"
                class="dark:text-gray-300"
              />
              <div class="flex flex-col gap-2 text-sm text-gray-800 dark:text-gray-300">
                <span>{{ t("settings.configOverride.defaultLocation") }}: {{ locationStore.defaultLocation.value || "-" }}</span>
                <span>{{ t("settings.configOverride.locationCount") }}: {{ locationStore.locations.value?.length || 0 }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- System Store Section -->
        <div class="bg-gray-50 dark:bg-gray-900 rounded-lg p-4">
          <div class="flex flex-col gap-3">
            <div class="text-sm font-medium text-gray-800 dark:text-gray-300">
              {{ t("settings.configOverride.systemStore") }}
            </div>
            <div class="flex flex-col gap-3">
              <div class="flex">
                <VButton
                  variant="is-danger is-outlined"
                  size="is-small"
                  icon-left="undo"
                  :loading="loading"
                  @click="() => systemStore.reset()"
                >
                  {{ t("settings.configOverride.reset") }}
                </VButton>
              </div>
            </div>
          </div>
        </div>

        <!-- Config Store Section -->
        <div class="bg-gray-50 dark:bg-gray-900 rounded-lg p-4">
          <div class="flex flex-col gap-3">
            <div class="text-sm font-medium text-gray-800 dark:text-gray-300">
              {{ t("settings.configOverride.configStore") }}
            </div>
            <div class="flex">
              <VButton
                :variant="disableMercure ? 'is-danger is-outlined' : 'is-info is-outlined'"
                size="is-small"
                :loading="loading"
                @click="() => toggleMercure()"
              >
                {{ t(`settings.configOverride.${disableMercure ? "enableMercure" : "disableMercure"}`) }}
              </VButton>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer="{ close }">
      <div class="w-full">
        <VDivider class="w-full !mb-6" />
        <div class="flex flex-col sm:flex-row justify-between gap-4">
          <VButton
            :loading="loading"
            rounded
            class="is-gray"
            @click="close"
          >
            {{ t("base.cancel") }}
          </VButton>
          <VButton
            rounded
            outlined
            icon-left="undo"
            :loading="loading"
            class="is-danger"
            @click="reset"
          >
            {{ t("settings.configOverride.revertToDefault") }}
          </VButton>
        </div>
      </div>
    </template>
  </VDialog>

  <CustomUrlDialog
    :visible="configOverrideDialog"
    @close="configOverrideDialog = false"
  />
</template>

<style scoped></style>
