<script setup lang="ts">
import type { NotificationHydraItem } from "@verbleif/lib";
import { iriToId, ScrollDirection, useLazyLoadScroll } from "@verbleif/lib";
import { TaskListType } from "@web/features/Tasks/TasksRoutes";
import moment from "moment";
import { useRouter } from "vue-router";
import { toast } from "vue-sonner";
import NavbarSettingsitem from "../NavbarSettingsItem.vue";
import { useNotifications } from "./useNotifications";

const { t } = useI18n();
const router = useRouter();
const container: Ref<HTMLElement | null> = ref(null);
const push = ref<boolean>(true);

const notifications = useNotifications();
if (!notifications) {
  throw new Error("Notifications must be provided");
}

const {
  isAllRead,
  items,
  loading,
  searchable,
  hasNextPage,
  currentPage,
  load,
  markAsRead,
  markOneAsRead,
  hasError,
  hasNotifications,
  checkUnreadCount,
} = notifications;

const TRANSLATION_KEY = "navbar.dropdown";

function fromNow(sentAt: string) {
  const currentTime = moment();
  const sentAtTime = moment(sentAt);
  const diffInSeconds = currentTime.diff(sentAtTime, "seconds");

  if (diffInSeconds < 60) {
    return t(`${TRANSLATION_KEY}.just_now`);
  } else {
    return sentAtTime.from(currentTime);
  }
}

async function notificationLocation(notification: NotificationHydraItem) {
  notification.readAt = new Date().toISOString();
  if (!notification.subject) {
    toast.error(t(`${TRANSLATION_KEY}.no_path`));
    return;
  }

  const id = iriToId(notification.subject);
  if (notification.subject.includes("task")) {
    router.push({ name: "task-list", params: { type: TaskListType.TYPE_ALL, taskId: `t${id}` } });
  } else if (notification.subject.includes("report")) {
    router.push({ name: "report-list", params: { type: "aall", reportId: `r${id}` } });
  } else {
    toast.error(t(`${TRANSLATION_KEY}.no_path`));
  }

  await markOneAsRead(notification);
}

async function handleMarkAsRead(notification: NotificationHydraItem, event: Event) {
  event.stopPropagation();
  await markOneAsRead(notification);
}

useLazyLoadScroll(
  container,
  (options: any) => {
    if (currentPage) {
      currentPage.value++;
      load(options);
    }
  },
  hasNextPage,
  loading,
  ScrollDirection.BOTTOM,
);

onMounted(() => {
  load();
  checkUnreadCount();
});
</script>

<template>
  <VDropdown
    theme="verbleif-popper-bounded"
    placement="right"
  >
    <template #popper>
      <div class="mx-5 text-gray-800 dark:text-white">
        <div class="flex mt-5 justify-between items-center">
          <div class="text-xl font-bold">
            {{ t(`${TRANSLATION_KEY}.notifications`) }}
          </div>
          <div
            class="text-sm cursor-pointer"
            :class="{
              'text-green-600 hover:text-green-700': hasNotifications && !isAllRead,
              'text-gray-300 pointer-events-none': !hasNotifications || isAllRead,
            }"
            @click="markAsRead()"
          >
            {{ t(`${TRANSLATION_KEY}.mark_all_read`) }}
          </div>
        </div>
        <div class="my-3">
          <VDivider />
        </div>
        <div v-if="push" class="flex items-center justify-between rounded-lg bg-red-600 bg-opacity-20 text-red-600 mb-4 font-bold">
          <div class="flex items-center">
            <FontAwesomeIcon class="ml-4 mr-3" :icon="['fa', 'thumbtack']" />
            <div>{{ t(`${TRANSLATION_KEY}.push_notification`) }}</div>
          </div>
          <RouterLink v-close-popper.all class="m-4" to="/settings/notifications">
            <FontAwesomeIcon :icon="['fa', 'arrow-up-right-from-square']" />
          </RouterLink>
        </div>
        <div>
          <VInput
            v-model="searchable.searchValue.value"
            :icon-left="['fa', 'magnifying-glass']"
            :icon-right="['fa', 'xmark']"
            :placeholder="t(`${TRANSLATION_KEY}.search`)"
            @on-right-icon-click="searchable.searchValue.value = ''"
          />
        </div>
        <div v-if="hasError" class="flex flex-col items-center justify-center p-8 text-center">
          <FontAwesomeIcon :icon="['fad', 'triangle-exclamation']" class="text-4xl text-red-500 mb-4" />
          <div class="text-gray-600 dark:text-gray-400">
            {{ t(`${TRANSLATION_KEY}.error_loading`) }}
          </div>
          <VButton variant="secondary" class="mt-4" @click="load">
            {{ t(`${TRANSLATION_KEY}.try_again`) }}
          </VButton>
        </div>
        <div
          v-else-if="!loading && !hasNotifications && !searchable.searchValue.value"
          class="flex flex-col items-center justify-center p-8 text-center"
        >
          <FontAwesomeIcon :icon="['fad', 'bell']" class="text-4xl text-gray-400 mb-4" />
          <div class="text-gray-600 dark:text-gray-400">
            {{ t(`${TRANSLATION_KEY}.no_notifications`) }}
          </div>
        </div>
        <div
          v-else-if="!loading && !hasNotifications && searchable.searchValue.value"
          class="flex flex-col items-center justify-center p-8 text-center"
        >
          <FontAwesomeIcon :icon="['fad', 'search']" class="text-4xl text-gray-400 mb-4" />
          <div class="text-gray-600 dark:text-gray-400">
            {{ t(`${TRANSLATION_KEY}.no_search_results`) }}
          </div>
        </div>
        <div v-else class="relative pb-4">
          <ul ref="container" class="flex flex-col w-96 h-96 overflow-y-scroll mt-3 gap-2 text-gray-800 dark:text-white">
            <li
              v-for="(notification, index) in items"
              :key="'skeleton' in notification ? `skeleton-${index}` : notification.id"
            >
              <template v-if="'skeleton' in notification">
                <div
                  class="flex pl-2 pr-3 py-3 rounded-lg group"
                >
                  <div class="flex h-6 w-5 items-center justify-center">
                    <VSkeleton
                      v-if="Math.round(Math.random())"
                      class="mb-1"
                      :dynamic-width="false"
                      :width="8"
                      :height="8"
                      width-unit="px"
                      height-unit="px"
                    />
                  </div>

                  <div class="flex flex-col w-full">
                    <div class="flex justify-between">
                      <VSkeleton class="mb-2" :width="100" :height="16" width-unit="px" height-unit="px" />
                      <VSkeleton class="mb-1" :width="100" :height="12" width-unit="px" height-unit="px" />
                    </div>
                    <div class="flex justify-between">
                      <div class="flex flex-col">
                        <VSkeleton class="mb-1" :width="200" :height="16" width-unit="px" height-unit="px" />
                        <VSkeleton class="mb-1" :width="150" :height="16" width-unit="px" height-unit="px" />
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template v-else>
                <div
                  v-close-popper.all="notification.subject && (notification.subject.includes('task') || notification.subject.includes('report'))"
                  class="flex pl-2 pr-3 py-3 rounded-lg group cursor-pointer"
                  @click="notificationLocation(notification)"
                >
                  <div
                    class="flex h-6 w-5 items-center justify-center cursor-pointer"
                  >
                    <div v-if="!notification.readAt" class="w-2 h-2 bg-primary-600 rounded-full" />
                  </div>
                  <div class="flex flex-col w-full">
                    <div class="flex justify-between">
                      <div :class="notification.readAt ? '' : 'font-bold'" class="dark:text-white">
                        {{ notification.title }}
                      </div>
                      <div v-if="notification.sentAt" class="text-gray-400 dark:text-grey-200 text-xs">
                        {{ fromNow(notification.sentAt) }}
                      </div>
                    </div>
                    <div class="w-fit">
                      <VTooltip
                        theme="verbleif-tooltip-dark"
                        placement="top"
                      >
                        <template #content>
                          {{ notification.subject?.includes('task')
                            ? t(`${TRANSLATION_KEY}.view_task`)
                            : t(`${TRANSLATION_KEY}.view_report`)
                          }}
                        </template>
                        <template #reference>
                          <div class="group-hover:text-primary-600">
                            {{ notification.subject }}
                          </div>
                        </template>
                      </VTooltip>
                    </div>
                    <div class="flex justify-between">
                      <div class="text-gray-500 font-normal dark:text-grey-300 w-3/4">
                        {{ notification.messageWeb }}
                      </div>
                      <div class="flex items-center">
                        <div v-if="!notification.readAt" class="relative group">
                          <VTooltip theme="verbleif-tooltip-dark" placement="top">
                            <template #content>
                              {{ t(`${TRANSLATION_KEY}.mark_as_read`) }}
                            </template>
                            <template #reference>
                              <FontAwesomeIcon
                                class="text-primary-600 text-xl hidden group-hover:flex cursor-pointer"
                                :icon="['fad', 'circle-check']"
                                @click="handleMarkAsRead(notification, $event)"
                              />
                            </template>
                          </VTooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <NavbarSettingsitem :icon="['fad', 'bell']" :text="t(`${TRANSLATION_KEY}.notifications`)" />
  </VDropdown>
</template>
