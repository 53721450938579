import type { ReportHydraItem } from "@verbleif/lib";
import type { PermissionService } from "../PermissionService";
import type { ReportVoter } from "./ReportVoter";
import type { ReportEntityVote } from "./voters.helpers";
import { PermissionAttributesEnum } from "@verbleif/lib";
import { ContextVoter } from "./composables/ContextVoter";

const reportFieldMapping: Record<
  "description" | "inside" | "priority" | "property" | "sort" | "status" | "topic",
  {
    create: PermissionAttributesEnum | null
    update: PermissionAttributesEnum
  }
> = {
  description: {
    create: null,
    update: PermissionAttributesEnum.ContextFieldReportUpdateDescription,
  },
  inside: {
    create: PermissionAttributesEnum.ContextFieldReportCreateInside,
    update: PermissionAttributesEnum.ContextFieldReportUpdateInside,
  },
  priority: {
    create: PermissionAttributesEnum.ContextFieldReportCreatePriority,
    update: PermissionAttributesEnum.ContextFieldReportUpdatePriority,
  },
  property: {
    create: PermissionAttributesEnum.ContextFieldReportCreateProperty,
    update: PermissionAttributesEnum.ContextFieldReportUpdateProperty,
  },
  sort: {
    create: null,
    update: PermissionAttributesEnum.ContextFieldReportUpdateSort,
  },
  status: {
    create: PermissionAttributesEnum.ContextFieldReportCreateStatus,
    update: PermissionAttributesEnum.ContextFieldReportUpdateStatus,
  },
  topic: {
    create: null,
    update: PermissionAttributesEnum.ContextFieldReportUpdateTopic,
  },
};

type ReportFieldAttribute = keyof typeof reportFieldMapping;

export class ReportFieldVoter extends ContextVoter {
  public reportVoter: ReportVoter;

  constructor(permissionService: PermissionService, reportVoter: ReportVoter) {
    super(permissionService);
    this.reportVoter = reportVoter;
  }

  public hasField = (entity: ReportEntityVote | null, operation: "create" | "update", field: ReportFieldAttribute): boolean => {
    if (reportFieldMapping[field] === undefined || reportFieldMapping[field][operation] === undefined) {
      return false;
    }

    const attribute = reportFieldMapping[field][operation];

    if (attribute === null) {
      return true;
    }

    if (entity) {
      if (this.permissionService.getGlobalPermissionForUser({
        attribute: operation === "create"
          ? PermissionAttributesEnum.GlobalFieldReportCreateany
          : PermissionAttributesEnum.GlobalFieldReportUpdateany,
      })) {
        return true;
      }
      return this.reportVoter.hasAccessToAttributeViaReportRelations(entity as ReportHydraItem, attribute);
    }

    return false;
  };
}
