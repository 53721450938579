import { useAuthStore, useLocationStore } from "@verbleif/shared";
import AuthRoutes from "@web/features/Auth/AuthRoutes";
import PublicRoutes from "@web/features/Public/PublicRoutes";
import ReportRoutes from "@web/features/Reports/ReportsRoutes";
import SettingsRoutes from "@web/features/Settings/SettingsRoutes";
import TaskRoutes from "@web/features/Tasks/TasksRoutes";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  ...SettingsRoutes,
  ...AuthRoutes,
  ...TaskRoutes,
  ...ReportRoutes,
  ...PublicRoutes,
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});
export default router;

const { returnUrl, hasToken, isRemembered } = useAuthStore();
const locationStore = useLocationStore();

router.beforeEach((to, _, next) => {
  if (to.meta.skipAuthChecks) {
    return next();
  }

  const isUnauthenticated = !hasToken.value && !isRemembered.value;
  if (isUnauthenticated && !to.meta.public) {
    console.log("isUnauthenticated", to);
    returnUrl.value = to.fullPath;
    console.log("[returnUrl]", returnUrl.value);
    return next({ name: "login" });
  }

  const isAuthenticated = hasToken.value || isRemembered.value;

  const wantsToGoToSpaces = to.name === "spaces";
  const skipLocationCheck = to.meta.skipLocationCheck;

  if (isAuthenticated) {
    if (!wantsToGoToSpaces && !skipLocationCheck) {
      if (!locationStore.selectedLocation.value) {
        return next({ name: "spaces" });
      }
    }
  }

  if (to.fullPath === returnUrl.value) {
    returnUrl.value = null;
  }

  return next();
});
