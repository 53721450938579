<script setup lang="ts">
import type { Placement, TriggerEvent } from "floating-vue";
import { Tooltip } from "floating-vue";
import { useAttrs } from "vue";

defineOptions({
  name: "VTooltip",
  inheritAttrs: false,
});
const {
  disabled = false,
  content = "Content prop",
  placement = "auto",
  delay,
  align = "center",
  theme = "verbleif-tooltip-gray",
  showGroup,
  popperClass,
  triggers,
} = defineProps<Props>();
const attrs = useAttrs();
interface Props {
  content?: string
  align?: "left" | "center" | "right" | "justify" | "start" | "end"
  theme?: "verbleif-tooltip-gray" | "verbleif-tooltip" | "verbleif-tooltip-ac" | "verbleif-tooltip-dark"
  disabled?: boolean
  delay?: string | number | {
    show: number
    hide: number
  }
  placement?: Placement
  showGroup?: string
  triggers?: TriggerEvent[]
  popperClass?: string
}
</script>

<template>
  <Tooltip
    :placement="placement"
    :disabled="disabled"
    :delay="delay"
    :show-arrow="showGroup"
    :triggers="triggers"
    :theme="theme"
    :popper-class="popperClass"
    :class="attrs.class"
  >
    <template #popper>
      <div :class="[`text-${align}`]">
        <slot name="content">
          {{ content }}
        </slot>
      </div>
    </template>
    <slot name="reference" />
  </Tooltip>
</template>
