import type { ContextVoterInterface } from "./composables/ContextVoter.interface";
import { PermissionAttributesEnum } from "@verbleif/lib";
import { ContextVoter } from "./composables/ContextVoter";

export class AppVoter extends ContextVoter implements ContextVoterInterface {
  public canCreate: ContextVoterInterface["canCreate"] = (item) => {
    return this._canCreate({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationAppCreateany,
      contextAttribute: PermissionAttributesEnum.ContextClientOperationAppCreate,
    });
  };

  public canRead: ContextVoterInterface["canRead"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationAppReadany,
      contextAttribute: PermissionAttributesEnum.ContextClientOperationAppRead,
    });
  };

  public canDelete: ContextVoterInterface["canDelete"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationAppDeleteany,
      contextAttribute: PermissionAttributesEnum.ContextClientOperationAppDelete,
    });
  };

  public canUpdate: ContextVoterInterface["canUpdate"] = (item) => {
    return this._canCrud({
      item,
      globalAttribute: PermissionAttributesEnum.GlobalOperationAppUpdateany,
      contextAttribute: PermissionAttributesEnum.ContextClientOperationAppUpdate,
    });
  };

  public canCrud: ContextVoterInterface["canCrud"] = (item = {
    client: this.permissionService.defaultClient,
    location: this.permissionService.defaultLocation,
  }) => {
    return this._canCrud({
      item,
      globalAttribute: [
        PermissionAttributesEnum.GlobalOperationAppCreateany,
        PermissionAttributesEnum.GlobalOperationAppUpdateany,
        PermissionAttributesEnum.GlobalOperationAppDeleteany,
      ],
      contextAttribute: [
        PermissionAttributesEnum.ContextClientOperationAppCreate,
        PermissionAttributesEnum.ContextClientOperationAppUpdate,
        PermissionAttributesEnum.ContextClientOperationAppDelete,
      ],
    });
  };
}
