<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { useStringToHsl } from "@verbleif/lib";
import { computed } from "vue";

interface Props {
  name?: string
  active?: boolean
  icon?: string | string[]
  inactiveIcon?: string | string[]
  size?: "is-small" | "is-medium" | "is-large"
  letterAmount?: number
  loading?: boolean
  color?: string
  avatarUrl?: string | undefined
  notifications?: number
  main?: boolean
  selected?: boolean
  showCloseIcon?: boolean
  showSelectedStyling?: boolean
}

const {
  name = "",
  active = true,
  icon = "",
  inactiveIcon = "",
  size = "is-small",
  main = false,
  letterAmount = 2,
  loading = false,
  color = undefined,
  notifications = 0,
  selected = false,
  showCloseIcon = false,
  showSelectedStyling = true,
} = defineProps<Props>();

const { stringToHslColor } = useStringToHsl();

const letters = computed(() => {
  const incomingName = name.split(" ").filter(n => n);

  if (incomingName.length === 1) {
    return incomingName[0].slice(0, letterAmount);
  }

  let letters = "";
  incomingName.forEach((part) => {
    if (letters.length >= letterAmount) {
      return;
    }
    letters += part[0];
  });

  return letters;
});

function getContrastYIQ(hexcolor: string) {
  // Convert to RGB first
  const r = Number.parseInt(hexcolor.replace("#", "").substr(0, 2), 16);
  const g = Number.parseInt(hexcolor.replace("#", "").substr(2, 2), 16);
  const b = Number.parseInt(hexcolor.replace("#", "").substr(4, 2), 16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  // Return black or white depending on YIQ value
  return yiq >= 200 ? "black" : "white";
}

const computedColor = computed(() => {
  if (loading) {
    return "#CCCCCC";
  }

  if (color) {
    return color;
  }

  return active ? stringToHslColor(name) : "#CCCCCC";
});

const textColor = computed(() => {
  return getContrastYIQ(computedColor.value);
});

const showClose = computed(() => {
  return showCloseIcon && selected;
});
</script>

<template>
  <div class="flex items-center text-text relative" :class="[size]">
    <div
      v-if="notifications > 0"
      class="flex justify-center items-center absolute -top-1 -right-1 bg-red-500 text-white w-5 h-5 rounded-full z-20"
      :class="[
        notifications > 99 ? 'text-[10px]' : 'text-xs',
      ]"
    >
      {{ notifications > 99 ? '99+' : notifications }}
    </div>
    <div
      class="rounded-full transition-all duration-200 relative"
      :class="{
        'w-6 h-6 text-[10px] font-medium': size === 'is-small',
        'w-[30px] h-[30px]': size === 'is-medium',
        'w-10 h-10 text-[17px]': size === 'is-large',
        'ring-2 ring-red-500 ring-offset-2 ring-offset-white dark:ring-offset-dark-600': selected && showSelectedStyling,
        'group-hover/item:ring-2 group-hover/item:ring-red-500 group-hover/item:ring-offset-2 group-hover/item:ring-offset-white dark:group-hover/item:ring-offset-dark-600': !selected && showSelectedStyling,
      }"
    >
      <Transition name="close-icon">
        <div
          v-if="showClose && showSelectedStyling"
          class="absolute -bottom-0.5 -right-2 bg-red-500 rounded-full w-4 h-4 flex items-center justify-center text-white shadow-sm z-10 ring-2 ring-white dark:ring-dark-600 text-center"
        >
          <FontAwesomeIcon
            :icon="['fas', 'xmark']"
            class="text-[12px]"
          />
        </div>
      </Transition>
      <span
        class="inline-flex rounded-full justify-center items-center text-white"
        :class="{
          'w-6 h-6 text-[10px] font-medium': size === 'is-small',
          'w-[30px] h-[30px]': size === 'is-medium',
          'w-10 h-10 text-[17px]': size === 'is-large',
        }"
        :style="{
          backgroundColor: computedColor,
          color: textColor,
        }"
      >
        <VSkeleton
          v-if="loading"
          pill
          :width="100"
          :height="100"
          height-unit="%"
          :dynamic-width="false"
        />
        <div
          v-else
          :class="{
            'text-lg font-bold': main,
            'text-xs': size === 'is-small',
            'text-sm': size === 'is-medium',
            'text-base': size === 'is-large',
          }"
          class="flex items-center text-center justify-center w-full h-full"
        >
          <template v-if="avatarUrl">
            <img :src="avatarUrl" alt="Avatar" class="w-full h-full rounded-full">
          </template>
          <template v-else-if="icon">
            <FontAwesomeIcon
              class="flex"
              :icon="icon"
              :class="{
                'text-xs': size === 'is-small',
                'text-sm': size === 'is-medium',
                'text-base': size === 'is-large',
                'text-lg font-bold': main,
              }"
            />
          </template>
          <template v-else>{{ letters.toUpperCase() }}</template>
        </div>
      </span>
    </div>
    <span v-if="!loading && !active && inactiveIcon" class="ml-1.5">
      <FontAwesomeIcon :icon="inactiveIcon" />
    </span>
  </div>
</template>

<style scoped>
.close-icon-enter-active,
.close-icon-leave-active {
  transition: all 0.2s ease;
}

.close-icon-enter-from,
.close-icon-leave-to {
  opacity: 0;
  transform: scale(0.7);
}
</style>
