export enum PermissionAttributesEnum {
  Context = "context.*",
  ContextClient = "context.client.*",
  ContextClientField = "context.client.field.*",
  ContextClientOperation = "context.client.operation.*",
  ContextClientOperationActivity = "context.client.operation.activity.*",
  ContextClientOperationActivityReadcollectionviareport = "context.client.operation.activity.read_collection_via_report",
  ContextClientOperationActivityReadcollectionviatask = "context.client.operation.activity.read_collection_via_task",
  ContextClientOperationApp = "context.client.operation.app.*",
  ContextClientOperationAppCreate = "context.client.operation.app.create",
  ContextClientOperationAppDelete = "context.client.operation.app.delete",
  ContextClientOperationAppRead = "context.client.operation.app.read",
  ContextClientOperationAppUpdate = "context.client.operation.app.update",
  ContextClientOperationAppsubscription = "context.client.operation.app_subscription.*",
  ContextClientOperationAppsubscriptionCreate = "context.client.operation.app_subscription.create",
  ContextClientOperationAppsubscriptionDelete = "context.client.operation.app_subscription.delete",
  ContextClientOperationAppsubscriptionRead = "context.client.operation.app_subscription.read",
  ContextClientOperationAppsubscriptionSetup = "context.client.operation.app_subscription.setup",
  ContextClientOperationAppsubscriptionUpdate = "context.client.operation.app_subscription.update",
  ContextClientOperationAppsubscriptionLocationLinkCreate = "context.client.operation.app_subscription_location_link.create",
  ContextClientOperationAppsubscriptionLocationLinkUpdate = "context.client.operation.app_subscription_location_link.update",
  ContextClientOperationAppsubscriptionLocationLinkDelete = "context.client.operation.app_subscription_location_link.delete",
  ContextClientOperationClient = "context.client.operation.client.*",
  ContextClientOperationClientRead = "context.client.operation.client.read",
  ContextClientOperationClientUpdate = "context.client.operation.client.update",
  ContextClientOperationClientDelete = "context.client.operation.client.delete",
  ContextClientOperationUser = "context.client.operation.user.*",
  ContextClientOperationUserReadcollectionviaclient = "context.client.operation.user.read_collection_via_client",
  ContextField = "context.field.*",
  ContextFieldReport = "context.field.report.*",
  ContextFieldReportCreate = "context.field.report.create.*",
  ContextFieldReportCreateInside = "context.field.report.create.inside",
  ContextFieldReportCreatePriority = "context.field.report.create.priority",
  ContextFieldReportCreateProperty = "context.field.report.create.property",
  ContextFieldReportCreateStatus = "context.field.report.create.status",
  ContextFieldReportUpdate = "context.field.report.update.*",
  ContextFieldReportUpdateDescription = "context.field.report.update.description",
  ContextFieldReportUpdateInside = "context.field.report.update.inside",
  ContextFieldReportUpdatePriority = "context.field.report.update.priority",
  ContextFieldReportUpdateProperty = "context.field.report.update.property",
  ContextFieldReportUpdateSort = "context.field.report.update.sort",
  ContextFieldReportUpdateStatus = "context.field.report.update.status",
  ContextFieldReportUpdateTopic = "context.field.report.update.topic",
  ContextFieldTask = "context.field.task.*",
  ContextFieldTaskCreate = "context.field.task.create.*",
  ContextFieldTaskCreateBlocking = "context.field.task.create.blocking",
  ContextFieldTaskCreateDeadlineat = "context.field.task.create.deadline_at",
  ContextFieldTaskCreateInside = "context.field.task.create.inside",
  ContextFieldTaskCreatePriority = "context.field.task.create.priority",
  ContextFieldTaskCreateProperty = "context.field.task.create.property",
  ContextFieldTaskCreateQuantity = "context.field.task.create.quantity",
  ContextFieldTaskUpdate = "context.field.task.update.*",
  ContextFieldTaskUpdateBlocking = "context.field.task.update.blocking",
  ContextFieldTaskUpdateDeadlineat = "context.field.task.update.deadline_at",
  ContextFieldTaskUpdateDescription = "context.field.task.update.description",
  ContextFieldTaskUpdateInside = "context.field.task.update.inside",
  ContextFieldTaskUpdatePriority = "context.field.task.update.priority",
  ContextFieldTaskUpdateProperty = "context.field.task.update.property",
  ContextFieldTaskUpdateQuantity = "context.field.task.update.quantity",
  ContextFieldTaskUpdateSort = "context.field.task.update.sort",
  ContextFieldTaskUpdateStatus = "context.field.task.update.status.*",
  ContextFieldTaskUpdateStatusTochecked = "context.field.task.update.status.to_checked",
  ContextFieldTaskUpdateStatusTofinishedorcheckedfuturedeadlineat = "context.field.task.update.status.to_finished_or_checked_future_deadline_at",
  ContextLocation = "context.location.*",
  ContextLocationField = "context.location.field.*",
  ContextLocationOperation = "context.location.operation.*",
  ContextLocationOperationReport = "context.location.operation.report.*",
  ContextLocationOperationReportCreateguestreport = "context.location.operation.report.create_guest_report",
  ContextLocationOperationUser = "context.location.operation.user.*",
  ContextLocationOperationUserReadcollectionvialocation = "context.location.operation.user.read_collection_via_location",
  ContextOperation = "context.operation.*",
  ContextOperationActivitycomment = "context.operation.activity_comment.*",
  ContextOperationActivitycommentCreate = "context.operation.activity_comment.create",
  ContextOperationActivitycommentDelete = "context.operation.activity_comment.delete",
  ContextOperationActivitycommentUpdate = "context.operation.activity_comment.update",
  ContextOperationActivityreportcomment = "context.operation.activity_report_comment.*",
  ContextOperationActivityreportcommentCreate = "context.operation.activity_report_comment.create",
  ContextOperationActivityreportcommentDelete = "context.operation.activity_report_comment.delete",
  ContextOperationActivityreportcommentUpdate = "context.operation.activity_report_comment.update",
  ContextOperationActivitytaskcomment = "context.operation.activity_task_comment.*",
  ContextOperationActivitytaskcommentCreate = "context.operation.activity_task_comment.create",
  ContextOperationActivitytaskcommentDelete = "context.operation.activity_task_comment.delete",
  ContextOperationActivitytaskcommentUpdate = "context.operation.activity_task_comment.update",
  ContextOperationDevice = "context.operation.device.*",
  ContextOperationDeviceCreate = "context.operation.device.create",
  ContextOperationDeviceDelete = "context.operation.device.delete",
  ContextOperationDeviceRead = "context.operation.device.read",
  ContextOperationDeviceReadcollectionviauser = "context.operation.device.read_collection_via_user",
  ContextOperationDeviceUpdate = "context.operation.device.update",
  ContextOperationGeneralledger = "context.operation.general_ledger.*",
  ContextOperationGeneralledgerCreate = "context.operation.general_ledger.create",
  ContextOperationGeneralledgerDelete = "context.operation.general_ledger.delete",
  ContextOperationGeneralledgerRead = "context.operation.general_ledger.read",
  ContextOperationGeneralledgerUpdate = "context.operation.general_ledger.update",
  ContextOperationLocation = "context.operation.location.*",
  ContextOperationLocationCreate = "context.operation.location.create",
  ContextOperationLocationDelete = "context.operation.location.delete",
  ContextOperationLocationRead = "context.operation.location.read",
  ContextOperationLocationUpdate = "context.operation.location.update",
  ContextOperationMediaobject = "context.operation.media_object.*",
  ContextOperationMediaobjectDelete = "context.operation.media_object.delete",
  ContextOperationMediaobjectRead = "context.operation.media_object.read",
  ContextOperationMediaobjectReadcollectionviadevice = "context.operation.media_object.read_collection_via_device",
  ContextOperationMediaobjectReadcollectionviareport = "context.operation.media_object.read_collection_via_report",
  ContextOperationMediaobjectReadcollectionviatask = "context.operation.media_object.read_collection_via_task",
  ContextOperationMediaobjectdeviceappstatemetadata = "context.operation.media_object_device_app_state_meta_data.*",
  ContextOperationMediaobjectdeviceappstatemetadataCreate = "context.operation.media_object_device_app_state_meta_data.create",
  ContextOperationMediaobjectreportmetadata = "context.operation.media_object_report_meta_data.*",
  ContextOperationMediaobjectreportmetadataCreate = "context.operation.media_object_report_meta_data.create",
  ContextOperationMediaobjectreportmetadataDelete = "context.operation.media_object_report_meta_data.delete",
  ContextOperationMediaobjectreportmetadataRead = "context.operation.media_object_report_meta_data.read",
  ContextOperationMediaobjecttaskmetadata = "context.operation.media_object_task_metadata.*",
  ContextOperationMediaobjecttaskmetadataCreate = "context.operation.media_object_task_metadata.create",
  ContextOperationMediaobjecttaskmetadataDelete = "context.operation.media_object_task_metadata.delete",
  ContextOperationMediaobjecttaskmetadataRead = "context.operation.media_object_task_metadata.read",
  ContextOperationMediaobjectuserprofilepicturemetadata = "context.operation.media_object_user_profile_picture_meta_data.*",
  ContextOperationMediaobjectuserprofilepicturemetadataCreate = "context.operation.media_object_user_profile_picture_meta_data.create",
  ContextOperationMediaobjectuserprofilepicturemetadataDelete = "context.operation.media_object_user_profile_picture_meta_data.delete",
  ContextOperationMediaobjectuserprofilepicturemetadataRead = "context.operation.media_object_user_profile_picture_meta_data.read",
  ContextOperationPermissiontemplatebracket = "context.operation.permission_template_bracket.*",
  ContextOperationPermissiontemplatebracketCreate = "context.operation.permission_template_bracket.create",
  ContextOperationPermissiontemplatebracketDelete = "context.operation.permission_template_bracket.delete",
  ContextOperationPermissiontemplatebracketRead = "context.operation.permission_template_bracket.read",
  ContextOperationPermissiontemplatebracketReadcollectionviatemplategroup = "context.operation.permission_template_bracket.read_collection_via_template_group",
  ContextOperationPermissiontemplatebracketUpdate = "context.operation.permission_template_bracket.update",
  ContextOperationPermissiontemplategroup = "context.operation.permission_template_group.*",
  ContextOperationPermissiontemplategroupCreate = "context.operation.permission_template_group.create",
  ContextOperationPermissiontemplategroupDelete = "context.operation.permission_template_group.delete",
  ContextOperationPermissiontemplategroupRead = "context.operation.permission_template_group.read",
  ContextOperationPermissiontemplategroupUpdate = "context.operation.permission_template_group.update",
  ContextOperationProduct = "context.operation.product.*",
  ContextOperationProductCreate = "context.operation.product.create",
  ContextOperationProductDelete = "context.operation.product.delete",
  ContextOperationProductRead = "context.operation.product.read",
  ContextOperationProductReservation = "context.operation.product.reservation.*",
  ContextOperationProductReservationCreate = "context.operation.product.reservation.create",
  ContextOperationProductReservationDelete = "context.operation.product.reservation.delete",
  ContextOperationProductReservationRead = "context.operation.product.reservation.read",
  ContextOperationProductReservationUpdate = "context.operation.product.reservation.update",
  ContextOperationProductTask = "context.operation.product.task.*",
  ContextOperationProductTaskCreate = "context.operation.product.task.create",
  ContextOperationProductTaskDelete = "context.operation.product.task.delete",
  ContextOperationProductTaskRead = "context.operation.product.task.read",
  ContextOperationProductTaskUpdate = "context.operation.product.task.update",
  ContextOperationProductUpdate = "context.operation.product.update",
  ContextOperationProperty = "context.operation.property.*",
  ContextOperationPropertyCreate = "context.operation.property.create",
  ContextOperationPropertyDelete = "context.operation.property.delete",
  ContextOperationPropertyRead = "context.operation.property.read",
  ContextOperationPropertyUpdate = "context.operation.property.update",
  ContextOperationPropertygroup = "context.operation.property_group.*",
  ContextOperationPropertygroupCreate = "context.operation.property_group.create",
  ContextOperationPropertygroupDelete = "context.operation.property_group.delete",
  ContextOperationPropertygroupRead = "context.operation.property_group.read",
  ContextOperationPropertygroupUpdate = "context.operation.property_group.update",
  ContextOperationReport = "context.operation.report.*",
  ContextOperationReportCreate = "context.operation.report.create",
  ContextOperationReportDelete = "context.operation.report.delete",
  ContextOperationReportDeletetransferredtotask = "context.operation.report.delete_transferred_to_task",
  ContextOperationReportRead = "context.operation.report.read",
  ContextOperationReportTransfertotask = "context.operation.report.transfer_to_task",
  ContextOperationReportUpdate = "context.operation.report.update",
  ContextOperationReporttopic = "context.operation.report_topic.*",
  ContextOperationReporttopicCreate = "context.operation.report_topic.create",
  ContextOperationReporttopicDelete = "context.operation.report_topic.delete",
  ContextOperationReporttopicRead = "context.operation.report_topic.read",
  ContextOperationReporttopicUpdate = "context.operation.report_topic.update",
  ContextOperationReservation = "context.operation.reservation.*",
  ContextOperationReservationCreate = "context.operation.reservation.create",
  ContextOperationReservationDelete = "context.operation.reservation.delete",
  ContextOperationReservationRead = "context.operation.reservation.read",
  ContextOperationReservationUpdate = "context.operation.reservation.update",
  ContextOperationReservationproduct = "context.operation.reservation_product.*",
  ContextOperationReservationproductCreate = "context.operation.reservation_product.create",
  ContextOperationReservationproductDelete = "context.operation.reservation_product.delete",
  ContextOperationReservationproductRead = "context.operation.reservation_product.read",
  ContextOperationReservationproductReadcollectionviareservation = "context.operation.reservation_product.read_collection_via_reservation",
  ContextOperationReservationproductUpdate = "context.operation.reservation_product.update",
  ContextOperationSort = "context.operation.sort.*",
  ContextOperationSortCreate = "context.operation.sort.create",
  ContextOperationSortDelete = "context.operation.sort.delete",
  ContextOperationSortRead = "context.operation.sort.read",
  ContextOperationSortUpdate = "context.operation.sort.update",
  ContextOperationSpace = "context.operation.space.*",
  ContextOperationSpaceAccept = "context.operation.space.accept",
  ContextOperationSpaceDecline = "context.operation.space.decline",
  ContextOperationSpaceRead = "context.operation.space.read",
  ContextOperationTask = "context.operation.task.*",
  ContextOperationTaskCreate = "context.operation.task.create",
  ContextOperationTaskCreateassigneddirectlytoscope = "context.operation.task.create_assigned_directly_to_scope",
  ContextOperationTaskCreateassigneddirectlytoself = "context.operation.task.create_assigned_directly_to_self",
  ContextOperationTaskDelete = "context.operation.task.delete",
  ContextOperationTaskDeleteassigneddirectlytoscope = "context.operation.task.delete_assigned_directly_to_scope",
  ContextOperationTaskDeleteassigneddirectlytoself = "context.operation.task.delete_assigned_directly_to_self",
  ContextOperationTaskRead = "context.operation.task.read",
  ContextOperationTaskReadassigneddirectlytoscope = "context.operation.task.read_assigned_directly_to_scope",
  ContextOperationTaskUpdate = "context.operation.task.update",
  ContextOperationTaskUpdateassigneddirectlytoscope = "context.operation.task.update_assigned_directly_to_scope",
  ContextOperationTaskUpdateassigneddirectlytoself = "context.operation.task.update_assigned_directly_to_self",
  ContextOperationTaskassignee = "context.operation.task_assignee.*",
  ContextOperationTaskassigneeCreate = "context.operation.task_assignee.create",
  ContextOperationTaskassigneeDelete = "context.operation.task_assignee.delete",
  ContextOperationTaskproduct = "context.operation.task_product.*",
  ContextOperationTaskproductCreate = "context.operation.task_product.create",
  ContextOperationTaskproductDelete = "context.operation.task_product.delete",
  ContextOperationTaskproductRead = "context.operation.task_product.read",
  ContextOperationTaskproductReadcollectionviatask = "context.operation.task_product.read_collection_via_task",
  ContextOperationTaskproductUpdate = "context.operation.task_product.update",
  ContextOperationTasktimeentry = "context.operation.task_time_entry.*",
  ContextOperationTasktimeentryCreate = "context.operation.task_time_entry.create",
  ContextOperationTasktimeentryDelete = "context.operation.task_time_entry.delete",
  ContextOperationTasktimeentryRead = "context.operation.task_time_entry.read",
  ContextOperationTasktimeentryReadcollectionviatask = "context.operation.task_time_entry.read_collection_via_task",
  ContextOperationTasktimeentryReadcollectionviauser = "context.operation.task_time_entry.read_collection_via_user",
  ContextOperationTasktimeentryUpdate = "context.operation.task_time_entry.update",
  ContextOperationUser = "context.operation.user.*",
  ContextOperationUserInvite = "context.operation.user.invite",
  ContextOperationUserRead = "context.operation.user.read",
  ContextOperationUserSyncpermissions = "context.operation.user.sync_permissions",
  ContextOperationUserUpdate = "context.operation.user.update",
  ContextOperationUsergroup = "context.operation.user_group.*",
  ContextOperationUsergroupCreate = "context.operation.user_group.create",
  ContextOperationUsergroupDelete = "context.operation.user_group.delete",
  ContextOperationUsergroupRead = "context.operation.user_group.read",
  ContextOperationUsergroupUpdate = "context.operation.user_group.update",
  ContextOperationUserpermission = "context.operation.user_permission.*",
  ContextOperationUserpermissionCreate = "context.operation.user_permission.create",
  ContextOperationUserpermissionDelete = "context.operation.user_permission.delete",
  ContextOperationUserpermissionMutatesimple = "context.operation.user_permission.mutate_simple",
  ContextOperationUserpermissionRead = "context.operation.user_permission.read",
  ContextOperationUserpermissionUpdate = "context.operation.user_permission.update",
  ContextOperationUserverification = "context.operation.user_verification.*",
  ContextOperationUserverificationRead = "context.operation.user_verification.read",
  ContextProperty = "context.property.*",
  ContextPropertyField = "context.property.field.*",
  ContextPropertyOperation = "context.property.operation.*",
  ContextPublicguestreport = "context.public_guest_report.*",
  ContextPublicguestreportCreate = "context.public_guest_report.create",
  ContextPublicguestreportValidatehash = "context.public_guest_report.validate_hash",
  ContextReportshortcut = "context.report_shortcut.*",
  ContextReportshortcutAllreports = "context.report_shortcut.all_reports",
  ContextReportshortcutGuestreports = "context.report_shortcut.guest_reports",
  ContextReportshortcutOpenreports = "context.report_shortcut.open_reports",
  ContextReportshortcutMyreports = "context.report_shortcut.my_reports",
  ContextReportshortcutTopics = "context.report_shortcut.topics",
  ContextReportshortcutTransferredtotask = "context.report_shortcut.transferred_to_task",
  ContextTask = "context.task.*",
  ContextTaskCreateassignedtoothersinscope = "context.task.create_assigned_to_others_in_scope",
  ContextTaskCreateforassignedusergrouplessuser = "context.task.create_for_assigned_user_group_less_user",
  ContextTaskCreateunassigned = "context.task.create_unassigned",
  ContextTaskDeleteassignedtoothersinscope = "context.task.delete_assigned_to_others_in_scope",
  ContextTaskDeleteforassignedusergrouplessuser = "context.task.delete_for_assigned_user_group_less_user",
  ContextTaskDeleteunassigned = "context.task.delete_unassigned",
  ContextTaskReadassignedofothersinscope = "context.task.read_assigned_of_others_in_scope",
  ContextTaskReadofassignedusergrouplessuser = "context.task.read_of_assigned_user_group_less_user",
  ContextTaskReadunassigned = "context.task.read_unassigned",
  ContextTaskUpdateassignedtoothersinscope = "context.task.update_assigned_to_others_in_scope",
  ContextTaskUpdateforassignedusergrouplessuser = "context.task.update_for_assigned_user_group_less_user",
  ContextTaskUpdateunassigned = "context.task.update_unassigned",
  ContextTaskassignee = "context.task_assignee.*",
  ContextTaskassigneeAssigntoothersinscope = "context.task_assignee.assign_to_others_in_scope",
  ContextTaskassigneeAssignusergroup = "context.task_assignee.assign_user_group",
  ContextTaskassigneeAssignusergrouplessuser = "context.task_assignee.assign_user_group_less_user",
  ContextTaskassigneeUnassignothersinscope = "context.task_assignee.unassign_others_in_scope",
  ContextTaskassigneeUnassignusergroup = "context.task_assignee.unassign_user_group",
  ContextTaskassigneeUnassignusergrouplessusers = "context.task_assignee.unassign_user_group_less_users",
  ContextTaskshortcut = "context.task_shortcut.*",
  ContextTaskshortcutAlltasks = "context.task_shortcut.all_tasks",
  ContextTaskshortcutAlltaskstoday = "context.task_shortcut.all_tasks_today",
  ContextTaskshortcutAlltasksuntiltoday = "context.task_shortcut.all_tasks_until_today",
  ContextTaskshortcutAssignedtome = "context.task_shortcut.assigned_to_me",
  ContextTaskshortcutChecked = "context.task_shortcut.checked",
  ContextTaskshortcutFinished = "context.task_shortcut.finished",
  ContextTaskshortcutMytaskstoday = "context.task_shortcut.my_tasks_today",
  ContextTaskshortcutMytasksuntiltoday = "context.task_shortcut.my_tasks_until_today",
  ContextTaskshortcutPerproperty = "context.task_shortcut.per_property",
  ContextTaskshortcutUnassigned = "context.task_shortcut.unassigned",
  ContextTaskshortcutUser = "context.task_shortcut.user",
  ContextTaskshortcutUsergrouplessuser = "context.task_shortcut.user_group_less_user",
  ContextTaskshortcutUsergroups = "context.task_shortcut.user_groups",
  ContextTaskshortcutWalkroute = "context.task_shortcut.walk_route",
  ContextUsergroup = "context.user_group.*",
  ContextUsergroupField = "context.user_group.field.*",
  ContextUsergroupOperation = "context.user_group.operation.*",
  ContextUserpermission = "context.user_permission.*",
  ContextUserpermissionAccesstype = "context.user_permission.access_type.*",
  ContextUserpermissionAccesstypeRegular = "context.user_permission.access_type.regular",
  ContextUserpermissionAccesstypeTemporarily = "context.user_permission.access_type.temporarily.*",
  ContextUserpermissionAccesstypeTemporarily30days = "context.user_permission.access_type.temporarily.30_days",
  ContextUserpermissionAccesstypeTemporarily48hours = "context.user_permission.access_type.temporarily.48_hours",
  Global = "global.*",
  GlobalField = "global.field.*",
  GlobalFieldReport = "global.field.report.*",
  GlobalFieldReportCreateany = "global.field.report.create_any",
  GlobalFieldReportUpdateany = "global.field.report.update_any",
  GlobalFieldTask = "global.field.task.*",
  GlobalFieldTaskCreateany = "global.field.task.create_any",
  GlobalFieldTaskUpdateany = "global.field.task.update_any",
  GlobalFieldUser = "global.field.user.*",
  GlobalFieldUserEmailverifiedat = "global.field.user.email_verified_at.*",
  GlobalFieldUserEmailverifiedatCreate = "global.field.user.email_verified_at.create",
  GlobalFieldUserEmailverifiedatUpdate = "global.field.user.email_verified_at.update",
  GlobalFieldUserLocale = "global.field.user.locale.*",
  GlobalFieldUserLocaleUpdate = "global.field.user.locale.update",
  GlobalFieldUserName = "global.field.user.name.*",
  GlobalFieldUserNameUpdate = "global.field.user.name.update",
  GlobalFieldUserPlainpassword = "global.field.user.plain_password.*",
  GlobalFieldUserPlainpasswordCreate = "global.field.user.plain_password.create",
  GlobalFieldUserPlainpasswordUpdate = "global.field.user.plain_password.update",
  GlobalFieldUserTimezone = "global.field.user.timezone.*",
  GlobalFieldUserTimezoneUpdate = "global.field.user.timezone.update",
  GlobalFieldUserUsername = "global.field.user.username.*",
  GlobalFieldUserUsernameUpdate = "global.field.user.username.update",
  GlobalOperation = "global.operation.*",
  GlobalOperationActivity = "global.operation.activity.*",
  GlobalOperationActivityReadany = "global.operation.activity.read_any",
  GlobalOperationActivityreportcomment = "global.operation.activity_report_comment.*",
  GlobalOperationActivityreportcommentCreateany = "global.operation.activity_report_comment.create_any",
  GlobalOperationActivityreportcommentDeleteany = "global.operation.activity_report_comment.delete_any",
  GlobalOperationActivityreportcommentUpdateany = "global.operation.activity_report_comment.update_any",
  GlobalOperationActivitytaskcomment = "global.operation.activity_task_comment.*",
  GlobalOperationActivitytaskcommentCreateany = "global.operation.activity_task_comment.create_any",
  GlobalOperationActivitytaskcommentDeleteany = "global.operation.activity_task_comment.delete_any",
  GlobalOperationActivitytaskcommentUpdateany = "global.operation.activity_task_comment.update_any",
  GlobalOperationApp = "global.operation.app.*",
  GlobalOperationAppCreateany = "global.operation.app.create_any",
  GlobalOperationAppDeleteany = "global.operation.app.delete_any",
  GlobalOperationAppReadany = "global.operation.app.read_any",
  GlobalOperationAppUpdateany = "global.operation.app.update_any",
  GlobalOperationAppsubscription = "global.operation.app_subscription.*",
  GlobalOperationAppsubscriptionCreateany = "global.operation.app_subscription.create_any",
  GlobalOperationAppsubscriptionDeleteany = "global.operation.app_subscription.delete_any",
  GlobalOperationAppsubscriptionReadany = "global.operation.app_subscription.read_any",
  GlobalOperationAppsubscriptionSetupany = "global.operation.app_subscription.setup_any",
  GlobalOperationAppsubscriptionUpdateany = "global.operation.app_subscription.update_any",
  GlobalOperationClient = "global.operation.client.*",
  GlobalOperationClientCreate = "global.operation.client.create",
  GlobalOperationClientDelete = "global.operation.client.delete",
  GlobalOperationClientReadany = "global.operation.client.read_any",
  GlobalOperationClientUpdate = "global.operation.client.update",
  GlobalOperationDevice = "global.operation.device.*",
  GlobalOperationDeviceCreateany = "global.operation.device.create_any",
  GlobalOperationDeviceDeleteany = "global.operation.device.delete_any",
  GlobalOperationDeviceReadany = "global.operation.device.read_any",
  GlobalOperationDeviceUpdateany = "global.operation.device.update_any",
  GlobalOperationGeneralledger = "global.operation.general_ledger.*",
  GlobalOperationGeneralledgerCreateany = "global.operation.general_ledger.create_any",
  GlobalOperationGeneralledgerDeleteany = "global.operation.general_ledger.delete_any",
  GlobalOperationGeneralledgerReadany = "global.operation.general_ledger.read_any",
  GlobalOperationGeneralledgerUpdateany = "global.operation.general_ledger.update_any",
  GlobalOperationLocation = "global.operation.location.*",
  GlobalOperationLocationCreateany = "global.operation.location.create_any",
  GlobalOperationLocationDeleteany = "global.operation.location.delete_any",
  GlobalOperationLocationReadany = "global.operation.location.read_any",
  GlobalOperationLocationUpdateany = "global.operation.location.update_any",
  GlobalOperationMediaobject = "global.operation.media_object.*",
  GlobalOperationMediaobjectDeleteany = "global.operation.media_object.delete_any",
  GlobalOperationMediaobjectReadany = "global.operation.media_object.read_any",
  GlobalOperationMediaobjectdeviceappstatemetadata = "global.operation.media_object_device_app_state_meta_data.*",
  GlobalOperationMediaobjectdeviceappstatemetadataDeleteany = "global.operation.media_object_device_app_state_meta_data.delete_any",
  GlobalOperationMediaobjectreportmetadata = "global.operation.media_object_report_meta_data.*",
  GlobalOperationMediaobjectreportmetadataCreateany = "global.operation.media_object_report_meta_data.create_any",
  GlobalOperationMediaobjectreportmetadataDeleteany = "global.operation.media_object_report_meta_data.delete_any",
  GlobalOperationMediaobjectreportmetadataReadany = "global.operation.media_object_report_meta_data.read_any",
  GlobalOperationMediaobjecttaskmetadata = "global.operation.media_object_task_metadata.*",
  GlobalOperationMediaobjecttaskmetadataCreateany = "global.operation.media_object_task_metadata.create_any",
  GlobalOperationMediaobjecttaskmetadataDeleteany = "global.operation.media_object_task_metadata.delete_any",
  GlobalOperationMediaobjecttaskmetadataReadany = "global.operation.media_object_task_metadata.read_any",
  GlobalOperationMediaobjectuserprofilepicturemetadata = "global.operation.media_object_user_profile_picture_meta_data.*",
  GlobalOperationMediaobjectuserprofilepicturemetadataCreateany = "global.operation.media_object_user_profile_picture_meta_data.create_any",
  GlobalOperationMediaobjectuserprofilepicturemetadataDeleteany = "global.operation.media_object_user_profile_picture_meta_data.delete_any",
  GlobalOperationMediaobjectuserprofilepicturemetadataReadany = "global.operation.media_object_user_profile_picture_meta_data.read_any",
  GlobalOperationPermissionresource = "global.operation.permission_resource.*",
  GlobalOperationPermissionresourceReadcollection = "global.operation.permission_resource.read_collection",
  GlobalOperationPermissiontemplatebracket = "global.operation.permission_template_bracket.*",
  GlobalOperationPermissiontemplatebracketCreateany = "global.operation.permission_template_bracket.create_any",
  GlobalOperationPermissiontemplatebracketDeleteany = "global.operation.permission_template_bracket.delete_any",
  GlobalOperationPermissiontemplatebracketReadany = "global.operation.permission_template_bracket.read_any",
  GlobalOperationPermissiontemplatebracketUpdateany = "global.operation.permission_template_bracket.update_any",
  GlobalOperationPermissiontemplategroup = "global.operation.permission_template_group.*",
  GlobalOperationPermissiontemplategroupCreateany = "global.operation.permission_template_group.create_any",
  GlobalOperationPermissiontemplategroupDeleteany = "global.operation.permission_template_group.delete_any",
  GlobalOperationPermissiontemplategroupReadany = "global.operation.permission_template_group.read_any",
  GlobalOperationPermissiontemplategroupUpdateany = "global.operation.permission_template_group.update_any",
  GlobalOperationProduct = "global.operation.product.*",
  GlobalOperationProductCreateany = "global.operation.product.create_any",
  GlobalOperationProductDeleteany = "global.operation.product.delete_any",
  GlobalOperationProductReadany = "global.operation.product.read_any",
  GlobalOperationProductUpdateany = "global.operation.product.update_any",
  GlobalOperationProperty = "global.operation.property.*",
  GlobalOperationPropertyCreateany = "global.operation.property.create_any",
  GlobalOperationPropertyDeleteany = "global.operation.property.delete_any",
  GlobalOperationPropertyReadany = "global.operation.property.read_any",
  GlobalOperationPropertyUpdateany = "global.operation.property.update_any",
  GlobalOperationPropertygroup = "global.operation.property_group.*",
  GlobalOperationPropertygroupCreateany = "global.operation.property_group.create_any",
  GlobalOperationPropertygroupDeleteany = "global.operation.property_group.delete_any",
  GlobalOperationPropertygroupReadany = "global.operation.property_group.read_any",
  GlobalOperationPropertygroupUpdateany = "global.operation.property_group.update_any",
  GlobalOperationReport = "global.operation.report.*",
  GlobalOperationReportCreateany = "global.operation.report.create_any",
  GlobalOperationReportDeleteany = "global.operation.report.delete_any",
  GlobalOperationReportReadany = "global.operation.report.read_any",
  GlobalOperationReportUpdateany = "global.operation.report.update_any",
  GlobalOperationReporttopic = "global.operation.report_topic.*",
  GlobalOperationReporttopicCreateany = "global.operation.report_topic.create_any",
  GlobalOperationReporttopicDeleteany = "global.operation.report_topic.delete_any",
  GlobalOperationReporttopicReadany = "global.operation.report_topic.read_any",
  GlobalOperationReporttopicUpdateany = "global.operation.report_topic.update_any",
  GlobalOperationReportTransfertotask = "global.operation.report.transfer_to_task",
  GlobalOperationReservation = "global.operation.reservation.*",
  GlobalOperationReservationCreateany = "global.operation.reservation.create_any",
  GlobalOperationReservationDeleteany = "global.operation.reservation.delete_any",
  GlobalOperationReservationReadany = "global.operation.reservation.read_any",
  GlobalOperationReservationUpdateany = "global.operation.reservation.update_any",
  GlobalOperationReservationproduct = "global.operation.reservation_product.*",
  GlobalOperationReservationproductCreateany = "global.operation.reservation_product.create_any",
  GlobalOperationReservationproductDeleteany = "global.operation.reservation_product.delete_any",
  GlobalOperationReservationproductReadany = "global.operation.reservation_product.read_any",
  GlobalOperationReservationproductUpdateany = "global.operation.reservation_product.update_any",
  GlobalOperationSort = "global.operation.sort.*",
  GlobalOperationSortCreateany = "global.operation.sort.create_any",
  GlobalOperationSortDeleteany = "global.operation.sort.delete_any",
  GlobalOperationSortReadany = "global.operation.sort.read_any",
  GlobalOperationSortUpdateany = "global.operation.sort.update_any",
  GlobalOperationSpace = "global.operation.space.*",
  GlobalOperationSpaceAcceptany = "global.operation.space.accept_any",
  GlobalOperationSpaceDeclineany = "global.operation.space.decline_any",
  GlobalOperationSpaceReadany = "global.operation.space.read_any",
  GlobalOperationSsetoken = "global.operation.sse_token.*",
  GlobalOperationSsetokenAny = "global.operation.sse_token.any",
  GlobalOperationTask = "global.operation.task.*",
  GlobalOperationTaskCreateany = "global.operation.task.create_any",
  GlobalOperationTaskDeleteany = "global.operation.task.delete_any",
  GlobalOperationTaskReadany = "global.operation.task.read_any",
  GlobalOperationTaskUpdateany = "global.operation.task.update_any",
  GlobalOperationTaskassignee = "global.operation.task_assignee.*",
  GlobalOperationTaskassigneeCreateany = "global.operation.task_assignee.create_any",
  GlobalOperationTaskassigneeDeleteany = "global.operation.task_assignee.delete_any",
  GlobalOperationTaskassigneeReadany = "global.operation.task_assignee.read_any",
  GlobalOperationTaskassigneeUpdateany = "global.operation.task_assignee.update_any",
  GlobalOperationTaskproduct = "global.operation.task_product.*",
  GlobalOperationTaskproductCreateany = "global.operation.task_product.create_any",
  GlobalOperationTaskproductDeleteany = "global.operation.task_product.delete_any",
  GlobalOperationTaskproductReadany = "global.operation.task_product.read_any",
  GlobalOperationTaskproductUpdateany = "global.operation.task_product.update_any",
  GlobalOperationTasktimeentry = "global.operation.task_time_entry.*",
  GlobalOperationTasktimeentryCreateany = "global.operation.task_time_entry.create_any",
  GlobalOperationTasktimeentryDeleteany = "global.operation.task_time_entry.delete_any",
  GlobalOperationTasktimeentryReadany = "global.operation.task_time_entry.read_any",
  GlobalOperationTasktimeentryUpdateany = "global.operation.task_time_entry.update_any",
  GlobalOperationUser = "global.operation.user.*",
  GlobalOperationUserDelete = "global.operation.user.delete",
  GlobalOperationUserInviteany = "global.operation.user.invite_any",
  GlobalOperationUserReadany = "global.operation.user.read_any",
  GlobalOperationUserSyncpermissionsany = "global.operation.user.sync_permissions_any",
  GlobalOperationUserUpdateany = "global.operation.user.update_any",
  GlobalOperationUsergroup = "global.operation.user_group.*",
  GlobalOperationUsergroupCreateany = "global.operation.user_group.create_any",
  GlobalOperationUsergroupDeleteany = "global.operation.user_group.delete_any",
  GlobalOperationUsergroupReadany = "global.operation.user_group.read_any",
  GlobalOperationUsergroupUpdateany = "global.operation.user_group.update_any",
  GlobalOperationUserpermission = "global.operation.user_permission.*",
  GlobalOperationUserpermissionCreateany = "global.operation.user_permission.create_any",
  GlobalOperationUserpermissionDeleteany = "global.operation.user_permission.delete_any",
  GlobalOperationUserpermissionReadany = "global.operation.user_permission.read_any",
  GlobalOperationUserpermissionUpdateany = "global.operation.user_permission.update_any",
  GlobalOperationUserverification = "global.operation.user_verification.*",
  GlobalOperationUserverificationReadany = "global.operation.user_verification.read_any",
  GlobalReportshortcut = "global.report_shortcut.*",
  GlobalReportshortcutAny = "global.report_shortcut.any",
  GlobalTaskshortcut = "global.task_shortcut.*",
  GlobalTaskshortcutAny = "global.task_shortcut.any",
}
