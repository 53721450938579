<script setup lang="ts">
import { useConfigStore } from "@verbleif/shared";
import { useMagicKeys } from "@vueuse/core";
import { watch } from "vue";
import FormDebugContent from "./FormDebugContent.vue";

const { size = "small", teleportTarget = "body" } = defineProps<{
  hideTrigger?: boolean
  size?: "small" | "medium" | "large"
  rounded?: boolean
  teleportTarget?: HTMLElement | "body" | "self"
  icon?: string[] | string
}>();

const emit = defineEmits<{
  (e: "show"): void
  (e: "hide"): void
}>();

defineSlots<{
  default?: void
  customTab?: void
  customTabItems?: void
  customTabPanels?: void
}>();

const visible = defineModel<boolean>("visible", {
  type: Boolean,
  default: false,
});

const { debugMode } = useConfigStore();

function show() {
  emit("show");
  visible.value = true;
}

const { Ctrl_Shift_F } = useMagicKeys();
watch(() => Ctrl_Shift_F.value, (value) => {
  if (value && debugMode.value) {
    show();
  }
});

defineExpose({
  show,
  visible,
});
</script>

<template>
  <div v-if="debugMode">
    <div
      v-if="!hideTrigger"
      class="trigger"
      @click="show"
    >
      <template v-if="$slots.default">
        <slot />
      </template>
      <VButton
        v-else
        variant="is-light"
        :size="size"
        :rounded="rounded"
      >
        <template v-if="icon">
          <FontAwesomeIcon :icon="icon" class="text-orange-500" />
        </template>
        <template v-else>
          Debug
        </template>
      </VButton>
    </div>
    <VDialog
      id="form-debug-dialog"
      :visible="visible"
      mask
      size="xlarge"
      :teleport-target="teleportTarget"
      @close="visible = false; emit('hide')"
    >
      <template #title>
        <strong class="text-lg">Vee-validate Debug</strong>
      </template>
      <template #content>
        <FormDebugContent>
          <template v-if="$slots.customTab" #customTab>
            <slot name="customTab" />
          </template>
          <template v-if="$slots.customTabItems" #customTabItems>
            <slot name="customTabItems" />
          </template>
          <template v-if="$slots.customTabPanels" #customTabPanels>
            <slot name="customTabPanels" />
          </template>
        </FormDebugContent>
      </template>
    </VDialog>
  </div>
  <template v-else>
    <slot />
  </template>
</template>

<style scoped lang="scss">
.trigger {
  :deep(*) {
    transition: 0.15s color;
  }
  &:hover :deep(*) {
    cursor: pointer;
    color: orange !important;
    transition: 0.15s color;
  }
}
</style>
