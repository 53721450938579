import type { PermissionService } from "../PermissionService";
import type { ReportVoter } from "./ReportVoter";
import type { TaskVoter } from "./TaskVoter";
import type { EntityVote } from "./voters.helpers";
import { PermissionAttributesEnum } from "@verbleif/lib";
import { ContextVoter } from "./composables/ContextVoter";

export class ActivityCommentVoter extends ContextVoter {
  public taskVoter: TaskVoter;
  public reportVoter: ReportVoter;

  constructor(permissionService: PermissionService, taskVoter: TaskVoter, reportVoter: ReportVoter) {
    super(permissionService);
    this.taskVoter = taskVoter;
    this.reportVoter = reportVoter;
  }

  public canCreate = (entity: EntityVote | null): boolean => {
    if (entity) {
      if (entity["@type"] === "Task") {
        if (this.permissionService.getGlobalPermissionForUser({
          attribute: PermissionAttributesEnum.GlobalOperationActivitytaskcommentCreateany,
        })) {
          return true;
        }

        return this.taskVoter.hasAccessToAttributeViaTaskRelations(entity, PermissionAttributesEnum.ContextOperationActivitytaskcommentCreate);
      }
      if (entity["@type"] === "Report") {
        if (this.permissionService.getGlobalPermissionForUser({
          attribute: PermissionAttributesEnum.GlobalOperationActivityreportcommentCreateany,
        })) {
          return true;
        }

        return this.reportVoter.hasAccessToAttributeViaReportRelations(entity, PermissionAttributesEnum.ContextOperationActivityreportcommentCreate);
      }
    }

    return false;
  };

  public canRead = (): boolean => {
    return false;
  };

  public canDelete = (entity: EntityVote | null): boolean => {
    if (entity) {
      if (entity["@type"] === "Task") {
        if (this.permissionService.getGlobalPermissionForUser({
          attribute: PermissionAttributesEnum.GlobalOperationActivitytaskcommentDeleteany,
        })) {
          return true;
        }

        return this.taskVoter.hasAccessToAttributeViaTaskRelations(entity, PermissionAttributesEnum.ContextOperationActivitytaskcommentDelete);
      }
      if (entity["@type"] === "Report") {
        if (this.permissionService.getGlobalPermissionForUser({
          attribute: PermissionAttributesEnum.GlobalOperationActivityreportcommentDeleteany,
        })) {
          return true;
        }

        return this.reportVoter.hasAccessToAttributeViaReportRelations(entity, PermissionAttributesEnum.ContextOperationActivityreportcommentDelete);
      }
    }

    return false;
  };

  public canUpdate = (entity: EntityVote | null): boolean => {
    if (entity) {
      if (entity["@type"] === "Task") {
        if (this.permissionService.getGlobalPermissionForUser({
          attribute: PermissionAttributesEnum.GlobalOperationActivitytaskcommentUpdateany,
        })) {
          return true;
        }
        return this.taskVoter.hasAccessToAttributeViaTaskRelations(entity, PermissionAttributesEnum.ContextOperationActivitytaskcommentUpdate);
      }
      if (entity["@type"] === "Report") {
        if (this.permissionService.getGlobalPermissionForUser({
          attribute: PermissionAttributesEnum.GlobalOperationActivityreportcommentUpdateany,
        })) {
          return true;
        }
        return this.reportVoter.hasAccessToAttributeViaReportRelations(entity, PermissionAttributesEnum.ContextOperationActivityreportcommentUpdate);
      }
    }

    return false;
  };
}
