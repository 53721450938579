import type { RouteRecordRaw } from "vue-router";

export enum ReportListType {
  TYPE_ALL = "aall",
  TYPE_OPEN = "op",
  TYPE_CREATED_BY_ME = "cbm",
  TYPE_GUEST = "gr",
  TYPE_TRANSFERRED = "tr",
  TYPE_REPORT_TOPIC = "rt",
}

const FALLBACK_TYPE = ReportListType.TYPE_CREATED_BY_ME;

export const routes = <RouteRecordRaw[]>[
  {
    path: "/r",
    name: "reports",
    component: () => import("./ReportsContainer.vue"),
    redirect: (props) => {
      return {
        name: "report-list",
        params: { type: FALLBACK_TYPE, ...props?.params },
      };
    },
    children: [
      {
        path: "",
        name: "reports-redirect",
        redirect: {
          name: "report-list",
          params: { type: FALLBACK_TYPE },
        },
      },
      {
        path: "/:catchAll(.*)",
        redirect: { name: "report-list", params: { type: FALLBACK_TYPE } },
      },
      {
        path: "li/:type/:id?/:reportId?",
        name: "report-list",
        component: () => import("./Overview/ReportsView.vue"),
        beforeEnter: (to, _, next) => {
          const type = <ReportListType>to.params.type;
          const { id, reportId } = to.params;

          // First validate the type
          if (!Object.values(ReportListType).includes(type)) {
            return next({
              name: "report-list",
              params: { type: FALLBACK_TYPE, reportId },
            });
          }

          console.log("Route->Params", {
            id,
            reportId,
          });

          // If we have a single parameter, check if it's actually a reportId
          if (id && !reportId && typeof id === "string" && id.startsWith("r")) {
            // Reorganize the params to move the ID to reportId
            return next({
              name: "report-list",
              params: {
                type,
                reportId: id,
                id: undefined,
              },
            });
          }

          return next();
        },
      },
    ],
  },
];

export default routes;
