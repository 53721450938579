import { useElementSize, useResizeObserver } from "@vueuse/core";
import { type MaybeRefOrGetter, ref, type Ref, toValue, watchEffect } from "vue";

export interface WidthCalculatorOptions {
  /**
   * Initial width before measurement (in pixels)
   * @default 14
   */
  initialWidth?: number

  /**
   * Minimum width constraint (in pixels)
   */
  minWidth?: number

  /**
   * Maximum width constraint (in pixels)
   */
  maxWidth?: number

  /**
   * Whether to observe size changes
   * @default true
   */
  observe?: boolean
}

/**
 * Composable for calculating and constraining element width
 *
 * @param options - Configuration options for width calculation
 * @returns Object containing width value and container ref
 */
export function useWidthCalculator(options: MaybeRefOrGetter<WidthCalculatorOptions> = {}) {
  const containerRef = ref<HTMLElement | null>(null);
  const width = ref<number>(toValue(options).initialWidth ?? 14);

  // Get real-time element dimensions
  const { width: elementWidth } = useElementSize(containerRef);

  // Update width based on constraints when options or element size changes
  watchEffect(() => {
    const opts = toValue(options);
    const currentWidth = elementWidth.value || width.value;

    // Apply min/max constraints if specified
    width.value = Math.min(
      Math.max(currentWidth, opts.minWidth ?? -Infinity),
      opts.maxWidth ?? Infinity,
    );
  });

  // Set up resize observer if enabled
  useResizeObserver(
    containerRef,
    () => {
      // Width updates will be handled by the watchEffect above
    },
  );

  return {
    width,
    containerRef: containerRef as Ref<HTMLElement | null>,
  };
}
