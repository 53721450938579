import type { NotificationHydraItem } from "@verbleif/lib";
import { LoopbackFilterLogicalOperatorEnum, LoopbackFilterOperatorEnum, providePaginate } from "@verbleif/lib";
import { api, useAuthStore, useServerErrorHandler } from "@verbleif/shared";
import { createInjectionState } from "@vueuse/core";
import { toast } from "vue-sonner";

const [useProvideNotifications, useNotifications] = createInjectionState(() => {
  const authStore = useAuthStore();
  const { t } = useI18n();
  const ENDPOINT = `/users/${authStore.user.value?.id}/notifications`;
  const TRANSLATION_KEY = "navbar.dropdown";
  const unreadCount = ref(0);
  const { onError } = useServerErrorHandler();

  // Setup pagination
  const {
    load,
    loading,
    items,
    searchable,
    cursor,
  } = providePaginate<NotificationHydraItem>({
    cursorParams: { partial: true },
    initialPerPage: 10,
    sortableFields: {},
    searchableFields: [
      {
        name: "title",
        operator: LoopbackFilterOperatorEnum.ILIKE,
        logicalOperator: LoopbackFilterLogicalOperatorEnum.OR,
      },
      {
        name: "messageWeb",
        operator: LoopbackFilterOperatorEnum.ILIKE,
        logicalOperator: LoopbackFilterLogicalOperatorEnum.OR,
      },
    ],
    onLoad: options => api.get(ENDPOINT, options),
  });

  // Add error state
  const error = ref<Error | null>(null);
  const hasError = computed(() => error.value !== null);
  const hasNotifications = computed(() => {
    const realItems = items.value.filter(item => !("skeleton" in item));
    return realItems.length > 0;
  });
  const isAllRead = computed(() => unreadCount.value === 0);

  // Methods
  async function checkUnreadCount() {
    try {
      error.value = null;
      const response = await api.get(`${ENDPOINT}?perPage=1&filter[readAt][eq]=null`);
      unreadCount.value = response.data.totalItems;
    } catch (err) {
      error.value = err as Error;
      onError(err);
    }
  }

  async function markAsRead() {
    if (!hasNotifications.value || isAllRead.value) {
      return;
    }

    try {
      error.value = null;
      await api.patch(`${ENDPOINT}/read`, {
        readAt: new Date().toISOString(),
      });

      items.value = items.value.map((item) => {
        if ("skeleton" in item) {
          return item;
        }
        return {
          ...item,
          readAt: new Date().toISOString(),
        };
      });

      unreadCount.value = 0;

      toast.success(t(`${TRANSLATION_KEY}.all_marked_read`));
    } catch (err) {
      error.value = err as Error;
      onError(err);
      toast.error(t(`${TRANSLATION_KEY}.mark_read_error`));
    }
  }

  async function markOneAsRead(notification: NotificationHydraItem) {
    if (!notification.id || notification.readAt) {
      return;
    }

    try {
      await api.patch(`/api/notifications/${notification.id}/read`, {
        readAt: new Date().toISOString(),
      });

      notification.readAt = new Date().toISOString();
      await checkUnreadCount();
    } catch (error) {
      onError(error);
    }
  }

  onMounted(() => {
    load();
    checkUnreadCount();
  });

  return {
    // State
    isAllRead,
    unreadCount,
    items,
    loading,
    searchable,
    hasNextPage: cursor.hasNextPage,
    currentPage: cursor.currentPage,

    // Methods
    load,
    markAsRead,
    markOneAsRead,
    checkUnreadCount,

    // State
    error,
    hasError,
    hasNotifications,
  };
});

export { useNotifications, useProvideNotifications };
