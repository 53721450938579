import { computed, inject, ref } from "vue";
import { useDialogStore } from "../../components/Dialog/dialogStore";
import { CONFIRM_DIALOG_KEY, type ConfirmDialogInstance } from "./useDefaultConfirmDialog";

export function useDialog(id: string, options?: {
  onClose?: () => void
  onConfirm?: () => void
  confirmClose?: boolean
  isDirty?: () => boolean
  teleportTarget?: string
  confirmCloseOptions?: {
    title: string
    message: string
    confirmText: string
    cancelText: string
    icon?: string
    type?: string
  }
}) {
  const dialog = ref<HTMLElement | null>(null);
  const { state, closeDialog, openDialog, registerDialog, getDialogZIndex } = useDialogStore();
  const isClosing = ref(false);
  const instanceId = ref<string>("");

  const confirmDialog = inject<ConfirmDialogInstance | null>(CONFIRM_DIALOG_KEY, null);

  const dialogZIndex = computed(() => getDialogZIndex(instanceId.value));

  if (options?.teleportTarget === "self") {
    instanceId.value = registerDialog(id, options.teleportTarget);
    if (!state.openOrder.includes(instanceId.value)) {
      state.openOrder.push(instanceId.value);
    }
  }

  async function closeThisDialog(event?: MouseEvent) {
    if (isClosing.value) {
      return;
    }

    if (event && event.target !== event.currentTarget) {
      return;
    }

    isClosing.value = true;

    try {
      const shouldConfirm = options?.confirmClose || (options?.isDirty && options.isDirty());

      if (shouldConfirm && options?.confirmCloseOptions && confirmDialog) {
        const confirmed = await confirmDialog.openDialog(options.confirmCloseOptions);
        if (confirmed) {
          closeDialog(instanceId.value);
          options.onClose?.();
        }
      } else {
        closeDialog(instanceId.value);
        options?.onClose?.();
      }
    } finally {
      isClosing.value = false;
    }
  }

  function openThisDialog() {
    instanceId.value = openDialog(id);
  }

  return {
    dialog,
    dialogZIndex,
    closeThisDialog,
    openThisDialog,
    isClosing,
    instanceId,
  };
}
