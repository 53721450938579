<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { defineProps, ref, watch } from "vue";
import { useWidthCalculator } from "../../composables";

const {
  value = null,
  iconLeft,
  iconRight = ["fas", "caret-down"],
  clearable = false,
  errorMessage = "",
  loading = false,
  labelLeft = "",
  required = false,
  disabled = false,
  placeholder = "placeholder",
  layout = "is-column",
} = defineProps<{
  value?: string | number | boolean | null
  iconLeft?: string | object
  iconRight?: string | Array<string>
  clearable?: boolean
  errorMessage?: string
  loading?: boolean
  labelLeft?: string
  required?: boolean
  disabled?: boolean
  placeholder?: string
  layout?: string
}>();

const emit = defineEmits(["change", "blur", "iconLeftClick"]);

const model = defineModel<string | number | boolean | null>();

const valueRef = ref(value || model.value);
const { containerRef: iconLeftRef, width: iconLeftWidth } = useWidthCalculator({
  initialWidth: 14,
  minWidth: 10,
  maxWidth: 40,
  observe: true,
});

// If an external change is made to the value, update the internal value
watch(
  () => model.value,
  (newValue, oldValue) => {
    if (newValue === oldValue) {
      return;
    }
    valueRef.value = newValue;
  },
);

// If we changed the internal value, update the model
watch(valueRef, (value, oldValue) => {
  if (value === oldValue) {
    return;
  }
  model.value = value;
});

function clear() {
  valueRef.value = null;
}

function handleIconLeftClick() {
  emit("iconLeftClick");
}
</script>

<template>
  <div class="select-container">
    <div
      class="select-sub-container"
      :class="[
        layout,
        {
          'is-loading': loading,
          'is-disabled': disabled,
        },
      ]"
    >
      <label
        v-if="labelLeft"
        :style="{
          paddingLeft:
            iconLeft ? `${iconLeftWidth + 10}px` : undefined,
        }"
        class="label"
        :class="{ 'w-1/3': layout === 'is-row' }"
      >
        {{ labelLeft }}
        <span v-if="required" class="required">*</span>
      </label>
      <VSkeleton
        v-if="loading"
        :height="40"
        :width="100"
        width-unit="%"
        :dynamic-width="false"
        style="border-radius: 5px"
        :pill="false"
      />
      <div
        v-else-if="!loading"
        class="select"
        :class="[
          errorMessage !== '' ? 'is-danger' : '',
          {
            'has-icon-left': iconLeft,
            'has-icon-right': iconRight,
            'is-clearable': clearable && valueRef !== null,
            'has-validation-error': errorMessage !== '',
          },
        ]"
      >
        <div v-if="iconLeft" ref="iconLeftRef" class="icon left" @click="handleIconLeftClick">
          <slot name="iconLeft">
            <FontAwesomeIcon :icon="iconLeft" />
          </slot>
        </div>
        <select
          v-model="valueRef"
          :class="{ 'is-placeholder': valueRef === null }"
          :disabled="disabled"
          @blur="$emit('blur')"
        >
          <slot
            name="placeholder"
            :placeholder="placeholder"
            :error-message="errorMessage"
            :value="valueRef"
          >
            <option disabled selected :value="null">
              {{ errorMessage !== "" ? errorMessage : placeholder }}
            </option>
          </slot>
          <slot />
        </select>
        <div
          v-if="clearable && valueRef !== null"
          class="icon clearable"
          @click="clear"
        >
          <FontAwesomeIcon :icon="['fas', 'times']" />
        </div>
        <div v-if="iconRight" class="icon right">
          <FontAwesomeIcon :icon="iconRight" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.select-container {
  position: relative;

  .select-sub-container {
    flex: 1 1 66%;
    display: flex;
    flex-direction: column;
    position: relative;
    font-family: "Roboto", sans-serif;
    font-size: 16px;

    &.is-disabled {
      cursor: not-allowed;
      opacity: 0.5;
      .select select:disabled {
        cursor: inherit;
      }
    }

    &.is-loading {
      cursor: progress;
    }

    &.is-row {
      flex-direction: row;
      align-items: center;

      .label {
        width: 33.33%;
      }
      .select {
        width: calc(100% - 33.33%);
      }

      &.has-label-left {
        padding-left: 15px;
      }
    }

    .label {
      display: inline-flex;
      color: var(--text);
      gap: 5px;

      .required {
        color: var(--secondary);
        font-weight: bold;
      }
    }

    .select {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 50px;
      border: 1px solid var(--input-border);
      height: 40px;

      &.is-primary {
        border: 1px solid var(--primary);
        .icon {
          color: var(--primary);
        }
      }

      &.is-secondary {
        border: 1px solid var(--secondary);
        .icon {
          color: var(--secondary);
        }
      }

      &.is-success {
        border: 1px solid var(--success);
        .icon {
          color: var(--success);
        }
      }

      &.is-info {
        border: 1px solid var(--info);
        .icon {
          color: var(--info);
        }
      }

      &.is-warning {
        border: 1px solid var(--warning);
        .icon {
          color: var(--warning);
        }
      }

      &.is-danger {
        border: 1px solid var(--danger);
        .icon {
          color: var(--danger);
        }
      }

      &.is-gray {
        border: 1px solid var(--input-border);
        background: red;
        .icon {
          color: var(--input-icon);
        }
      }

      // Extra styling when validation error is occurred
      &.is-danger.has-validation-error {
        select {
          color: var(--danger);

          &::placeholder {
            font-weight: bold;
            color: var(--danger);
          }
        }
      }

      &.is-gray, &.is-white {
        background-color: var(--input-background);
        color: var(--text);
        &::placeholder {
          color: var(--input-placeholder);
        }
      }

      .icon {
        position: absolute;
        color: var(--text);
        font-size: 14px;
        display: inline-flex;
        align-items: center;

        &.left {
          left: 0;
          margin-left: 15px;
        }
        &.clearable {
          right: 35px;
          margin-left: auto;
          cursor: pointer;

          &:hover {
            color: var(--primary-dark);
          }
        }
        &.right {
          margin-right: 15px;
          right: 0;

          .clear-icon {
            margin-right: 5px;
          }

          &.has-action {
            cursor: pointer;
          }
        }
      }

      select {
        flex: 1;
        border-radius: 5px;
        background-color: transparent;
        color: var(--text);
        border: none;
        transition: 100ms ease background-color;
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
        cursor: pointer;
        padding-left: 20px;
        font-size: inherit;
        box-sizing: content-box;
        -moz-box-sizing: content-box;
        -webkit-box-sizing: content-box;
        padding-top: 2px; //without this, the text is not vertically centered

        &::placeholder,
        &.is-placeholder {
          color: var(--input-placeholder);
        }

        &:focus {
          outline: none;
        }
      }

      &.has-icon-left {
        select {
          padding-left: 40px;
        }
      }

      &.is-clearable:not(.has-icon-right),
      &.has-icon-right:not(.is-clearable) {
        select {
          padding-right: 30px;
        }
      }

      &.is-clearable.has-icon-right {
        select {
          padding-right: 50px;
        }
      }
    }
  }
}
</style>
